import { Space } from 'antd'
import Text from 'antd/lib/typography/Text'
import styled from 'styled-components'

export const MiniCartWrapper = styled(Space)`
  position: absolute;
  right: -25px;
  text-align: center;
  background-color: #fff;
  padding: 30px;
  min-width: 387px;
  border-radius: 2px;
  z-index: 99;
  top: 54px;
  filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.15));
  @media (max-width: 400px) {
    min-width: unset;
  }
  @media (max-width: 300px) {
    width: 265px;
    padding-left: 10px;
    padding-right: 10px;
  }

  &::before {
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 7px 7px 7px;
    border-color: transparent transparent #fff transparent;
    position: absolute;
    top: -7px;
    right: 35px;
  }
`

export const MiniCartTitle = styled(Text)`
  font-family: Sora;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 26px;
  text-align: center;

  color: #f16749;
`

export const EmptyMiniCartTitle = styled(Text)`
  font-family: Sora;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 26px;
  text-align: center;

  color: #7b2e6f;
`

export const EmptyMiniCartSubTitle = styled(Text)`
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  text-align: center;

  color: #6b6b6b;
  margin-bottom: '4px';
`

export const MiniCartItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const MiniCartItems = styled(Space)``

export const MiniCartItem = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr 1fr 1fr;
  border-bottom: 1px solid #e2e2e2;
  padding-bottom: 22px;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  > .ant-space.ant-space-vertical {
    margin-left: 0px;
    margin-right: 10px;
  }
  @media (max-width: 400px) {
    > .ant-space.ant-space-vertical {
      margin-left: 0px;
    }
  }
  @media (max-width: 300px) {
    grid-template-columns: 1fr 3fr;
    .ant-input-number {
      margin-top: 10px;
    }
  }

  .product-name {
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
    color: #6b6b6b;
    text-align: left;
    display: block;
  }

  .product-options {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #6b6b6b;
    text-align: left;
    display: block;
  }

  .product-price {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    color: #6b6b6b;
    min-width: 50px;
    display: block;
  }
`

export const TotalPriceWrapper = styled(Space)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 7px;
  margin-bottom: 10px;

  .total-price-label {
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    color: #393939;
  }

  .total-price-value {
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    text-align: right;
    color: #393939;
  }
`
