import styled, { css } from 'styled-components'
import Text from 'antd/lib/typography/Text'
import {
  Layout,
  Menu,
  Space,
  Button,
  Dropdown,
  Image,
  Row,
  List,
  Avatar,
  Divider,
  Modal,
} from 'antd'
import {
  CaretDownOutlined,
  ShoppingFilled,
  MenuOutlined,
  FacebookFilled,
  InstagramOutlined,
  CloseOutlined,
} from '@ant-design/icons'
import Title from 'antd/lib/typography/Title'

const { Header, Footer } = Layout

interface StyledListProp {
  $active: boolean
}

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  background-color: #f0f0f0;
  min-height: 100vh;
`

export const Navbar = styled.div`
  grid-column: 1;
  padding: 20px 10px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-around;

  > div a + a {
    margin-left: 20px;
  }
`

export const Content = styled.div`
  grid-column: 1;
  padding: 20px 10px;
  max-width: 1400px;
  margin: 20px auto;
  width: 100%;
`
export const StyledTopHeader = styled(Space)`
  justify-content: space-between;
  background-color: #f16749;
  height: 38px;
  @media (max-width: 767px) {
    display: none;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    display: none;
  }
`

export const StyledBtnTopHead = styled(Button)`
  margin: 0;
  font-size: 14px;
  color: #ffffff;
  font-weight: 600;
`
export const StyledTextTopHead = styled(Text)`
  margin: 0;
  font-size: 14px;
  color: #ffffff;
  font-weight: 700;
  font-family: Lato;
`

export const StyledMiddleHeader = styled(Space)`
  justify-content: space-between;
  width: 100%;
  line-height: initial;
  @media (max-width: 767px) {
    padding-bottom: 16px;
  }
  & > div:first-child {
    @media (max-width: 1199px) {
      flex: 0 100%;
    }
  }
`

export const StyledLeftContent = styled(Space)`
  @media (max-width: 1199px) {
    justify-content: space-between;
    width: 100%;
  }
  .loading-categories-menu {
    margin-top: 5px;
  }
  @media (max-width: 1199px) {
    .loading-categories-menu {
      display: none;
    }
  }

  & > div:first-child {
    @media (min-width: 1200px) {
      display: none;
    }
  }

  & > .ant-space-item {
    @media (max-width: 767px) {
      margin-right: 0px !important;
    }

    & .logo a .ant-image {
      @media (max-width: 767px) {
        width: auto !important;
        height: auto !important;
      }
    }
  }
`

export const StyledDropdown = styled(Dropdown)`
  @media (max-width: 767px) {
    display: none;
  }
`

export const StyledDropdownIcon = styled(CaretDownOutlined)`
  padding-left: 10px;
`

export const StyledDropdownAnchor = styled.a`
  color: #393939;
  font-size: 14px;
  margin-right: 14px;
  font-family: Lato;
  font-weight: 700;
  line-height: 24px;
`
export const StyledAccountBtn = styled(Button)`
  margin-right: 14px;
  @media (max-width: 767px) {
    display: none;
  }
`

export const StyleShoppingFilled = styled(ShoppingFilled)`
  font-size: 18px;
`

export const StyledMenuIcon = styled(MenuOutlined)`
  display: none;
  font-size: 28px;
  @media (max-width: 767px) {
    display: block;
  }
`
export const StyledHeaderMenu = styled(Menu)`
  border-bottom: unset !important;
  & .ant-menu-item {
    border: unset !important;
  }

  @media (max-width: 1199px) {
    display: none;
  }
`

export const StyledHeader = styled(Header)`
  position: sticky;
  top: 0;
  z-index: 999;
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(67, 4, 59, 0.2);
  @media (max-width: 767px) {
    height: 72px;
    align-items: flex-end;
    padding: 0 30px;
  }
`
export const StyledLogoImage = styled(Image)`
  @media (max-width: 1199px) {
    margin-left: 36px !important;
  }
  @media (max-width: 767px) {
    width: 89px;
    margin: 0 auto;
    height: auto !important;
  }
`
export const StyledRightContent = styled(Space)`
  & > div:nth-child(1) {
    @media (max-width: 1199px) {
      display: none;
    }
  }
  & > div:nth-child(2) {
    @media (max-width: 1199px) {
      display: none;
    }
  }
  & > div:nth-child(3) {
    display: none;
  }
  & > div:nth-child(3) {
    @media (max-width: 1199px) {
      display: block;
    }
  }
`
export const StyledFooter = styled(Footer)`
  text-align: center;
  background-color: #43043b;
  z-index: 1;
  position: relative;
  padding-bottom: 24px;

  @media (max-width: 767px) {
    padding-bottom: 40px;
    padding-top: 100px;
  }

  & > .styles__StyledContainer-cw0kl1-0 > .ant-space-item {
    margin-right: 0 !important;
  }
`
export const StyledFooterSpace = styled(Row)`
  justify-content: space-between;
  width: 100%;
  padding-top: 110px;
  padding-bottom: 145px;
  @media (max-width: 767px) {
    padding-bottom: 40px;
    padding-top: 0;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 100px;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    flex-wrap: wrap;
    padding: 80px 0;
  }

  @media (min-width: 1200px) and (max-width: 1450px) {
    padding-bottom: 60px;
    padding-top: 60px;
  }

  & .ant-space-item:nth-child(2) {
    @media (min-width: 992px) and (max-width: 1199px) {
      flex-basis: 80%;
    }
  }

  > .ant-space-item:nth-child(3) {
    @media (min-width: 992px) and (max-width: 1199px) {
      flex-basis: 100%;
      text-align: right;
      padding-top: 60px;
    }
  }
`
export const StyledRow = styled(Row)`
  flex-wrap: nowrap;
  @media (max-width: 991px) {
    flex-wrap: wrap;
    margin: 0 !important;
    padding-top: 31px;
    padding-bottom: 14px;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    padding-bottom: 40px;
    padding-top: 40px;
    justify-content: flex-start;
    flex-wrap: wrap;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    margin: 0 !important;
    flex-wrap: wrap;
    text-align: left;
  }
`
export const StyledListItemHeader = styled.div`
  border: unset !important;
  display: flex;
  @media (max-width: 767px) {
    justify-content: flex-start;
  }
`

export const StyledListItem = styled(List.Item)`
  border: unset !important;
  @media (max-width: 767px) {
    justify-content: flex-start;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    padding: 4px 0 !important;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    font-size: 16px;
  }
`

export const StyledListTitle5 = styled(Title)`
  font-size: 18px !important;
  margin-bottom: 13px !important;
  font-family: Sora;
  color: #f16749 !important;
  line-height: 24px !important;
  @media (max-width: 767px) {
    margin-bottom: 0 !important;
    font-size: 16px !important;
    line-height: 24px !important;
    font-weight: 600 !important;
  }
`

export const StyledTitle5 = styled(Title)`
  margin-bottom: 13px !important;
  color: #f16749 !important;
  line-height: 26px !important;
  @media (max-width: 767px) {
    margin-bottom: 0 !important;
    font-size: 18px !important;
    line-height: 24px !important;
  }
`

export const StyledAnchor = styled.a`
  color: #ffffff;
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  font-family: Lato;

  @media (min-width: 768px) and (max-width: 991px) {
    font-size: 16px;
  }
`
export const StyledButton = styled(Button)`
  width: 208px;
  @media (max-width: 767px) {
    width: 166px;
    margin-bottom: 8px;
  }
`

export const StyledButtonBgPurple = styled(Button)`
  width: 208px;
  background-color: #7b2e6f;
  margin-bottom: 5px;
  border: unset !important;

  @media (max-width: 767px) {
    width: 166px;
  }

  & :hover {
    background-color: rgba(123, 46, 111, 0.7);
  }
`

export const StyledFacebookIcon = styled(FacebookFilled)`
  color: #f16749;
  font-size: 28px;
`

export const StyledInstagramIcon = styled(InstagramOutlined)`
  color: #f16749;
  font-size: 28px;
  margin-left: 15px;
  margin-right: 15px;
  @media (max-width: 767px) {
    margin-right: 19px;
  }
`

export const StyledSpaceDowloadApp = styled(Space)`
  padding: 60px 0px 100px;

  @media (max-width: 767px) {
    padding: 22px 0px 34px;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    padding: 0;
    position: relative;
    top: -250px;
    align-items: flex-start;
    width: 100%;
  }

  @media (min-width: 1200px) and (max-width: 1450px) {
    padding: 30px 0px 30px;
  }

  & .ant-image {
    @media (max-width: 767px) {
      width: 209px !important;
      height: 70px !important;
    }
  }

  & .ant-image img {
    @media (max-width: 767px) {
      height: auto !important;
    }
  }
`
export const StyledSpaceCopyright = styled(Space)`
  justify-content: space-between;
  width: 100%;
  @media (max-width: 991px) {
    flex-direction: column;
  }
`
export const StyledText = styled(Text)`
  color: #ffffff;
  font-family: Lato;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
`

export const StyledCopyrightAnchor = styled.a`
  color: #ffffff;
  font-family: Lato;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
`
export const StyledTextCopyrightAnchor = styled.a`
  color: #ffffff !important;
  font-family: Lato;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  padding-left: 4px;
  transition: all 0.5s !important;

  & :hover {
    color: #ff9375 !important;
  }
`
export const StyledLogoSpace = styled(Space)`
  display: flex;
  & .ant-image {
    @media (max-width: 767px) {
      width: 137px !important;
      height: 72px !important;
    }
  }

  & .ant-image img {
    @media (max-width: 767px) {
      height: auto !important;
    }
  }
`
export const StyledCopyrightSpace = styled(Space)`
  @media (max-width: 991px) {
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 32px;
  }

  & .ant-space-item:nth-child(1) {
    @media (max-width: 991px) {
      flex-basis: 100%;
      justify-content: center;
      margin-bottom: 19px;
      margin-right: 0 !important;
    }
  }

  & .ant-space-item:nth-child(2) {
    @media (max-width: 991px) {
      margin-right: 0 !important;
    }
  }
`

export const CustomerAvatar = styled(Avatar)`
  background: #fdede9;
  color: #f16749;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const FolloUsWrapper = styled(Space)`
  display: flex;

  a {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    color: #ffffff;

    &:hover {
      color: #ff9375;
    }
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    & > .ant-space-item:nth-child(2) .ant-space {
      column-count: 2;
      display: block;
      text-align: left;
    }
  }
`

export const FolloUsWrapperTitle = styled(Text)`
  font-family: Sora;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #f16749;
  text-align: left;
  display: block;
  @media (min-width: 768px) and (max-width: 991px) {
    text-align: center;
  }

  @media (max-width: 767px) {
    display: none;
  }
`

export const PaymentMethodsWrapper = styled(Space)`
  display: flex;
  text-align: left;

  @media (min-width: 992px) and (max-width: 1199px) {
    & > .ant-space-item:nth-child(2) > .ant-space > .ant-space-item {
      width: auto;
      height: auto;
      flex-basis: auto;
    }
  }

  @media (max-width: 991px) {
    align-items: center;
  }
`

export const PaymentMethodsTitle = styled(Text)`
  font-family: Sora;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #f16749;
  text-align: left;
  display: block;
  @media (min-width: 768px) and (max-width: 991px) {
    text-align: center;
  }

  @media (max-width: 767px) {
    display: none;
  }
`

export const FooterDivider = styled(Divider)`
  margin: 0;
  border-top: 1px solid #ffffff33;
`

export const StyledFooterBottom = styled(Space)`
  display: flex;
  justify-content: space-between;
  margin-top: 40px;

  > .ant-space-item {
    flex: 1;

    &:nth-of-type(1) {
      text-align: left;
    }

    &:nth-of-type(3) {
      text-align: right;
    }
  }

  @media (max-width: 991px) {
    flex-direction: column;

    > .ant-space-item {
      margin-right: 0 !important;

      &:nth-of-type(1) {
        order: 1;
        margin-bottom: 44px;
      }

      &:nth-of-type(2) {
        order: 0;
        margin-bottom: 19px;
      }

      &:nth-of-type(3) {
        order: 2;
      }
    }
  }

  @media (min-width: 1200px) {
    & > .ant-space-item:nth-of-type(1) {
      flex: 0 40%;
    }
  }
`

export const StyledCopyright = styled(Space)`
  @media (min-width: 1450px) {
    & span.ant-typography {
      white-space: nowrap;
    }
  }

  @media (max-width: 767px) {
    > .ant-space-item {
      &:nth-of-type(1) .ant-typography .ant-typography {
        display: block;
        text-align: center;
      }
    }

    > .ant-space-item {
      &:nth-of-type(1) .ant-typography {
        font-size: 12px;
        line-height: 16px;
      }
    }

    > .ant-space-item {
      &:nth-of-type(1) .ant-typography a {
        font-size: 12px;
        line-height: 16px;
      }
    }
  }
  @media (max-width: 350px) {
    > .ant-space-item {
      &:nth-of-type(1) .ant-typography {
        font-size: 12px;
        line-height: 16px;
        text-align: center;
        display: block;
      }
    }
  }

  @media (min-width: 768px) and (max-width: 991px) {
    > .ant-space-item {
      &:nth-of-type(1) .ant-typography .ant-typography {
        display: block;
        text-align: center;
      }
    }
  }
`

export const StyledTermsAndConditions = styled(Space)`
  a {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    color: #ffffff;
    transition: all 0.5s;
  }

  a:hover {
    color: #ff9375;
  }
`

export const StyledLivroReclamacoes = styled(Space)`
  @media (max-width: 767px) {
    max-width: 141px;
  }
`
export const StyledList = styled(List)`
  & .ant-list-header {
    border-bottom: unset;
  }

  @media (max-width: 767px) {
    border-bottom: 1px solid #f0f0f0;

    & .ant-spin-nested-loading {
      padding-bottom: 0px;
      padding-left: 16px;
      transition: all 0.5s;
    }

    & .ant-list-header {
      border-bottom: unset;
      padding-left: 16px;
    }

    & .ant-list-header .ant-list-item {
      position: relative;
    }

    & .ant-list-header .ant-list-item:before {
      content: '';
      position: absolute;
      right: 20.515px;
      height: 10px;
      width: 1px;
      background: #ffffff;
      transition: all 0.5s;
    }

    & .ant-list-header .ant-list-item:after {
      content: '';
      position: absolute;
      right: 16px;
      width: 10px;
      height: 1px;
      background: #ffffff;
      transition: all 0.5s;
    }

    & .ant-list-header > div {
      position: relative;
      align-items: center;
    }

    & .ant-list-header > div:before {
      content: '';
      position: absolute;
      right: 20.514px;
      height: 10px;
      width: 1px;
      background: #ffffff;
      transition: all 0.5s;
    }

    & .ant-list-header > div:after {
      content: '';
      position: absolute;
      right: 16px;
      width: 10px;
      height: 1px;
      background: #ffffff;
      transition: all 0.5s;
    }

    & .ant-spin-nested-loading .ant-spin-container {
      padding-bottom: 0px;
      max-height: 0;
      overflow: hidden;
      transition: all 0.5s;
    }

    ${(props: StyledListProp) =>
      props.$active &&
      css`
        & .ant-spin-nested-loading {
          padding-bottom: 13px;
          padding-left: 16px;
          transition: all 0.5s;
        }

        & .ant-list-header {
          border-bottom: unset;
          padding-left: 16px;
        }

        .ant-spin-nested-loading .ant-spin-container {
          max-height: 400px;
          overflow: unset;
        }

        & .ant-list-header .ant-list-item:before {
          transform: rotate(90deg);
        }

        & .ant-list-header > div {
          position: relative;
          align-items: center;
        }

        & .ant-list-header > div:before {
          content: '';
          position: absolute;
          right: 20.515px;
          height: 10px;
          width: 1px;
          background: #ffffff;
          transition: all 0.5s;
        }

        & .ant-list-header > div:after {
          content: '';
          position: absolute;
          right: 16px;
          width: 10px;
          height: 1px;
          background: #ffffff;
          transition: all 0.5s;
        }

        & .ant-list-header > div:before {
          transform: rotate(90deg);
        }
      `}
  }

  @media (min-width: 768px) {
    & .ant-list-header {
      padding: 0;
    }
  }
`

export const StyledCloseIcon = styled(CloseOutlined)`
  font-size: 28px;
`
export const StyledDiv = styled.div`
  .text {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    color: #6b6b6b;
    margin: 0;
  }
  .ant-picker-large .ant-picker-input > input {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    /* identical to box height, or 171% */

    /* Neutral / 80 - P */

    color: #393939;
  }
  .ant-picker-large {
    max-width: 472px;
    width: 100%;
  }
  .button {
    margin-top: 16px;
    padding: 8px 16px;
    background-color: #f16749;
    float: right;
  }
`
export const StyledModal = styled(Modal)`
  .ant-modal-footer {
    padding: 0;
    padding-bottom: 56px;
  }
  .ant-modal-close {
    display: none;
  }
`
