import { AdsDownloadAppProps } from '@/components/HomePage/DownloadAppSection'
import { AdsHomePageBannerProps } from '@/components/HomePage/HomePagePrincipalBanner'
import { useClient } from '@/contexts/ClientContext'
import { AdsProps } from '@/Props/HomePage/ProductSectionProps'
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook'

export default function sendSponsorInfoToGTM(
  isVisible: boolean,
  sponsor: any,
  type: string,
  adsSentToGTM: string[],
  sendDataToGTM: any
) {
  if (isVisible && sponsor) {
    const name = sponsor.name_ads
      ? sponsor.name_ads
      : sponsor.subtitle_ads
      ? sponsor.subtitle_ads
      : sponsor.title_ads
      ? sponsor.title_ads
      : ''

    const adsName = name.toLocaleUpperCase().split('')
    adsName.map((char, index) => {
      const checkIfHasWhiteSpace = (s) => {
        return /\s/g.test(s)
      }
      const hasWhiteSpace = checkIfHasWhiteSpace(char)
      if (hasWhiteSpace) {
        adsName[index] = '-'
      }
    })
    const checkIfWasSentBefore = adsSentToGTM.find(
      (each) => each == type + adsName.join('').toLocaleLowerCase()
    )

    if (!checkIfWasSentBefore) {
      adsSentToGTM.push(type + adsName.join('').toLocaleLowerCase())
      //console.log('saw', type + adsName.join('').toLocaleLowerCase())
      sendDataToGTM({ ecommerce: null })
      sendDataToGTM({
        event: 'promotionView',
        ecommerce: {
          promoView: {
            promotions: [
              {
                id: adsName.join(''),
                name: name,
                creative: type + adsName.join('').toLocaleLowerCase(),
                position: type + adsName.join('').toLocaleLowerCase(),
              },
            ],
          },
        },
      })
    }
  }
}
