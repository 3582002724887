import React, { useEffect, useState } from 'react'
import { Badge, Col, Button, Dropdown, Modal, DatePicker, Checkbox, Divider } from 'antd'
import Link from 'next/link'
import {
  StyledTopHeader,
  StyledBtnTopHead,
  StyledTextTopHead,
  StyledMiddleHeader,
  /* StyledDropdown,
  StyledDropdownAnchor,
  StyledDropdownIcon, */
  StyledAccountBtn,
  StyleShoppingFilled,
  StyledMenuIcon,
  StyledHeader,
  StyledHeaderMenu,
  StyledLeftContent,
  StyledLogoImage,
  StyledRightContent,
  StyledFooter,
  StyledFooterSpace,
  StyledRow,
  StyledListItem,
  StyledListItemHeader,
  StyledListTitle5,
  StyledAnchor,
  StyledText,
  StyledTextCopyrightAnchor,
  StyledLogoSpace,
  CustomerAvatar,
  FolloUsWrapper,
  FolloUsWrapperTitle,
  PaymentMethodsWrapper,
  PaymentMethodsTitle,
  FooterDivider,
  StyledFooterBottom,
  StyledCopyright,
  StyledTermsAndConditions,
  StyledLivroReclamacoes,
  StyledList,
  StyledCloseIcon,
  StyledDiv,
  StyledModal,
} from './styles'

//import api from '@/services/api'
import '@/styles/antd-client.less'
import { Layout, Menu, Space } from 'antd'
import { StyledContainer } from '../shared/Container/styles'
import api from '@/services/api'
import { CategoryProps, CategoryPropsPrincipal } from '@/Props/CategoryProps'
import { useRouter } from 'next/router'
import Image from 'next/image'
import { SearchInput } from '../shared/SearchInput'
import { useSession } from 'next-auth/client'
import { RiUserFill } from 'react-icons/ri'
import Text from 'antd/lib/typography/Text'
import { MiniCart } from '../shared/MiniCart'
import Cookies from 'js-cookie'
import { LegalWarning } from '../shared/LegalWarning'
import SubMenu from 'antd/lib/menu/SubMenu'
import { signOut } from 'next-auth/client'
import styled from 'styled-components'
import { CookiesPopup } from '@/components/shared/CookiesPopup'
import { PageSEO } from '../shared/PageSEO'
import cmsApi from '@/services/cmsApi'
import { StrapiCategoryProps } from '@/Props/HomePage/ProductSectionProps'
import moment from 'moment'
import { CustomerProps } from '@/Props/CustomerProps'
import * as gtag from '../../lib/gtag'
import { useMultiVendorCart } from '@/contexts/MultiVendorCartContext'
import MaskedInput from 'antd-mask-input'
import { useClient } from '@/contexts/ClientContext'
import TagManager from 'react-gtm-module'
import * as Sentry from '@sentry/nextjs'
import { MapsPlacesAutocomplete } from '@/components/shared/MapsPlacesAutocomplete'
import googleMapsAutocompleteParser from '@/utils/googleMapsAutocompleteParser'
import { ModalTopContent } from '../shared/AddressInfoTopBar/styles'
import { CustomerAddressProps } from '@/Props/CustomerAddressProps'
import { InfoCircleOutlined } from '@ant-design/icons'
import { StrapiImageProps } from '@/Props/StrapiImageProps'
import { isAfter, isBefore } from 'date-fns'
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook'
import { SponsoredTag } from '../HomePage/HomePagePrincipalBanner/styles'
import VisibilitySensor from 'react-visibility-sensor'
import sendSponsorInfoToGTM from '@/utils/sendSponsorInfoToGTM'
import { MultiVendorCartItemProps, MultiVendorCartProps } from '@/Props/MultiVendorCartProps'
import axios from 'axios'
import WhatsappIcon from '../Whatsapp'
import { useCategory } from '@/contexts/CategoryContext'
import ContentLoader from 'react-content-loader'
import { browserName, browserVersion, osName, osVersion } from 'react-device-detect'
export const StyledMenuBtn = styled(Button)`
  height: auto;
  padding: 0;
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
`
interface AdsProps {
  name_ads: string
  slug_ads: string
  image_ads: StrapiImageProps
  image_link_ads: string
  image_target_ads: string
  begin_date_ads: string
  end_date_ads: string
}
const { Content } = Layout

/* const menu = (
  <Menu>
    <Menu.Item>PT</Menu.Item>
    <Menu.Item>EN</Menu.Item>
    <Menu.Item>FR</Menu.Item>
    <Menu.Item>ES</Menu.Item>
  </Menu>
) */

const Logout = () => {
  Cookies.remove('clientAddress')
  Cookies.remove('cart-shipping-type')
  Cookies.remove('customer')
  Cookies.remove('clientLongitude')
  Cookies.remove('cart')
  Cookies.remove('customer-token')
  Cookies.remove('clientLatitude')
  Cookies.remove('cart-id')
  Cookies.remove('cart-retailer-id')
  Cookies.remove('cart-shipping-time')
  signOut({ callbackUrl: '/' })
}

const optionsMenu = (
  <Menu>
    <Menu.Item>
      <Link href={`/cliente/conta`}>
        <a>
          <Text>A minha conta</Text>
        </a>
      </Link>
    </Menu.Item>
    <Menu.Item>
      <Link href={`/cliente/conta/encomendas`}>
        <a>
          <Text>As minhas encomendas</Text>
        </a>
      </Link>
    </Menu.Item>
    <Menu.Item>
      <Link href={`/cliente/conta/favoritos`}>
        <a>
          <Text>A minha lista de desejos</Text>
        </a>
      </Link>
    </Menu.Item>
    <Menu.Item>
      <StyledMenuBtn type="link" onClick={() => Logout()} danger>
        Terminar sessão
      </StyledMenuBtn>
    </Menu.Item>
  </Menu>
)

const RenderMenuImage = ({ category }) => {
  const [cmsCategorie, setCmsCategorie] = useState<StrapiCategoryProps>()
  const router = useRouter()
  const [adToShow, setAdToShow] = useState<AdsProps>()
  const sendDataToGTM = useGTMDispatch()
  const [adsSentToGTM, setAdsSentToGTM] = useState<string[]>([])

  useEffect(() => {
    if (category.cms_id) {
      try {
        cmsApi
          .get<StrapiCategoryProps>(`categories/${category.cms_id}`)
          .then(({ data: categories }) => {
            setCmsCategorie(categories)
          })
      } catch (error) {
        // Sentry.captureException(error)
      }
    }
  }, [category])

  useEffect(() => {
    if (cmsCategorie) {
      if (cmsCategorie.multiple_content_categories) {
        let adsFound = false
        cmsCategorie.multiple_content_categories.map((eachAd) => {
          if (eachAd.image_ads && eachAd.name_ads && !adsFound) {
            if (eachAd.begin_date_ads && eachAd.end_date_ads) {
              if (
                isAfter(new Date(), new Date(eachAd.begin_date_ads)) &&
                isBefore(new Date(), new Date(eachAd.end_date_ads))
              ) {
                adsFound = true
                setAdToShow(eachAd)
              }
            }
          }
        })
      }
    }
  }, [cmsCategorie])

  const promotionClick = () => {
    if (adToShow) {
      const adsName = adToShow.name_ads.toLocaleUpperCase().split('')
      adsName.map((char, index) => {
        const checkIfHasWhiteSpace = (s) => {
          return /\s/g.test(s)
        }
        const hasWhiteSpace = checkIfHasWhiteSpace(char)
        if (hasWhiteSpace) {
          adsName[index] = '-'
        }
      })
      sendDataToGTM({ ecommerce: null })
      sendDataToGTM({
        event: 'promotionClick',
        ecommerce: {
          promoView: {
            promotions: [
              {
                id: adsName.join(''),
                name: adToShow.name_ads,
                creative: `submenu-image-${adsName.join('').toLocaleLowerCase()}`,
                position: `submenu-image-${adsName.join('').toLocaleLowerCase()}`,
              },
            ],
          },
        },
      })
    }
  }

  if (adToShow) {
    return (
      <VisibilitySensor
        onChange={(isVisible) =>
          sendSponsorInfoToGTM(isVisible, adToShow, `submenu-image-`, adsSentToGTM, sendDataToGTM)
        }
      >
        <div className="submenu-image">
          <div style={{ position: 'relative' }}>
            <Image
              width="449"
              height="225"
              src={adToShow.image_ads?.url}
              alt={adToShow.image_ads?.alternativeText}
            />
            {adToShow && <SponsoredTag style={{ bottom: 40, right: 10 }}>Ad</SponsoredTag>}
          </div>
          <Link href={adToShow.image_link_ads != null ? adToShow.image_link_ads : '/'}>
            <a
              onClick={() => promotionClick()}
              aria-hidden="true"
              target={adToShow.image_target_ads != 'nova_pagina' ? '' : '_blank'}
            >
              {''}
            </a>
          </Link>
        </div>
      </VisibilitySensor>
    )
  } else if (cmsCategorie?.image?.url && cmsCategorie?.image?.url !== null) {
    return (
      <div className="submenu-image">
        <Image
          width="449"
          height="225"
          src={cmsCategorie.image.url}
          alt={cmsCategorie.image.alternativeText}
        />
        <Link href={cmsCategorie.image_link != null ? cmsCategorie.image_link : '/'}>
          <a target={cmsCategorie.image_target != 'nova_pagina' ? '' : '_blank'}>sa</a>
        </Link>
      </div>
    )
  }

  return null
}

const renderMenuItem = (category, changeCategorySelected, isVisibleAfterHours) => {
  if (category.is_sub_category) {
    return null
  }
  if (category.is_visible == false) {
    return null
  }
  if (category.is_night_category && !isVisibleAfterHours) {
    return null
  }
  if (category.childCategories) {
    //var chunks = category.childCategories.chunkArray(4);
    ////console.log(chunks);

    return (
      <Menu.Item key={`categoria-${category.slug}`} className="has-submenu">
        <Link href={`/categoria/${category.slug}`}>
          {category.is_night_category && isVisibleAfterHours ? (
            <a>
              <img alt={category.name} src="/assets/images/after-hours-logo.svg" />
            </a>
          ) : (
            <a style={{ color: category.is_promotion_category ? '#f16749' : '' }}>
              {category.name}
            </a>
          )}
        </Link>
        {!category.is_night_category && (
          <div className="submenu-dropdown">
            <div className="container">
              <div className="submenu-row">
                <div className="submenu-list">
                  <div className="submenu-title">{category.name}</div>
                  <ul>
                    {category.childCategories
                      .filter((childCategory) => childCategory.is_visible == true)
                      .slice(0, 11)
                      .map((subcategory) => (
                        <li key={subcategory.slug}>
                          <Link href={`/categoria/${subcategory.slug}`}>
                            <a>{subcategory.name}</a>
                          </Link>
                        </li>
                      ))}
                    <li>
                      <Link href={`/categoria/${category.slug}`}>
                        <a
                          aria-hidden="true"
                          onClick={() => changeCategorySelected(category)}
                          className="see-all"
                        >
                          Ver tudo
                        </a>
                      </Link>
                    </li>
                  </ul>
                </div>
                <RenderMenuImage category={category} />
              </div>
            </div>
          </div>
        )}
      </Menu.Item>
    )
  }

  return (
    <Menu.Item key={category.slug}>
      <Link href={`/categoria/${category.slug}`}>
        <a aria-hidden="true" onClick={() => changeCategorySelected(category)}>
          {category.name}
        </a>
      </Link>
    </Menu.Item>
  )
}

interface PageLayoutProps {
  title?: string
  description?: string
  slug?: string
  shareImage?: string
  menuCategories?: CategoryProps[]
}

const defaultPageTitle = 'SW!G - Bring it on.'
const defaultPageDescription =
  'Não importa se estás na casa da mãe, da vizinha ou da sogra. Vamos ter contigo a qualquer lugar. Basta inserir uma morada, selecionar o momento em que queres receber as tuas bebidas favoritas... et voilá! Podes começar a escolher.'
const defaultPageShareImage =
  'https://res.cloudinary.com/dusacesxk/image/upload/v1620832045/website_defaults/share-image.jpg'

const PageLayout: React.FC<PageLayoutProps> = ({
  children,
  title = defaultPageTitle,
  description = defaultPageDescription,
  shareImage = defaultPageShareImage,
  slug = '',
  menuCategories,
}) => {
  const router = useRouter()
  const { updatedInfo, changeUpdatedInfo, addressError } = useClient()
  const {
    changeClientAddress,
    changeClientCoordinates,
    changeClientCity,
    changeClientDistrict,
    changeClientCountry,
    changeClientPostalCode,
    changeClientAddressId,
    address,
    changeAddressName,
    isClientLoading,
    changeIsLoading,
    addressData,
    changeCategorySelected,
    deliveryZoneError,
    changeDeliveryZoneError,
    changeAddressError,
    changeIp,
    changeIsVisibleAfterHours,
    isVisibleAfterHours,
    changeIsVisibleAfterHoursLoading,
    district,
    changeClientFromIslands,
  } = useClient()
  const {
    totalItems,
    isMiniCartVisible,
    isLoading,
    changeCartId,
    changeCartProducts,
  } = useMultiVendorCart()
  const [session, loadingSession] = useSession()
  const [categories, setCategories] = useState<CategoryProps[]>([])
  const [customHaveLegalAge, setCustomHaveLegalAge] = useState(true)
  const [customerHaveAcceptCookies, setCustomerAcceptCookies] = useState(true)
  const [aboutUsItems, setAboutUsItems] = useState(false)
  const [contactItems, setContactItems] = useState(false)
  const [partnerItems, setPartnerItems] = useState(false)
  const [openMenu, setOpenMenu] = useState(false)
  const [isBirthdayModalOpen, setIsBirthdayModalOpen] = useState(false)
  const [newsletterValue, setNewsletterValue] = useState(false)
  const [date, setDate] = useState<moment.Moment>(null)
  const [phone, setPhone] = useState(null)
  const [hasDate, setHasDate] = useState(false)
  const [localAddress, setLocalAddress] = useState('')
  const [isAddressDeleted, setIsAddressDeleted] = useState(false)
  const [invalidNumber, setInvalidNumber] = useState(false)
  const [isVisible, setIsVisible] = useState(false)
  const [linkWhatsapp, setLinkWhatsapp] = useState('')
  const { categoriesMenu, changeCategoriesMenu } = useCategory()
  const [loadingCategories, setLoadingCategories] = useState(false)
  useEffect(() => {
    api.get('/whatsapp-business-isvisible').then(({ data: { is_visible, link } }) => {
      setIsVisible(is_visible)
      setLinkWhatsapp(link)
    })
    changeIsVisibleAfterHoursLoading(true)
    api.get('/after-hours-isvisible').then(({ data: is_visible }) => {
      changeIsVisibleAfterHours(is_visible)
      changeIsVisibleAfterHoursLoading(false)
    })
    const email = Cookies.get('clientEmail')
    const name = Cookies.get('clientName')
    const lastSent = Cookies.get('sent_active')
    const now = new Date().getTime()
    if (email && (!lastSent || (lastSent && now - Number(lastSent) > 1800000))) {
      api.get(
        `/active-on-site?email=${email}${
          name ? `&name=${name}` : ''
        }&browser=${browserName} v${browserVersion}&os=${osName} v${osVersion}&page=${
          process.env.NEXT_PUBLIC_WEBSITE_URL + router.asPath
        }`
      )
      Cookies.set('sent_active', now.toString())
    }
  }, [router])
  useEffect(() => {
    if (session && session.customer) {
      Cookies.set('clientEmail', session.customer.email, { expires: 60 })
      Cookies.set('clientName', session.customer.name, { expires: 60 })
      const lastSent = Cookies.get('sent_active')
      const now = new Date().getTime()
      if (!lastSent || (lastSent && now - Number(lastSent) > 1800000)) {
        api.get(
          `/active-on-site?email=${session.customer.email}${
            session.customer.name ? `&name=${session.customer.name}` : ''
          }&browser=${browserName} v${browserVersion}&os=${osName} v${osVersion}&page=${
            process.env.NEXT_PUBLIC_WEBSITE_URL + router.asPath
          }`
        )
        Cookies.set('sent_active', now.toString())
      }
    }
  }, [session])
  // useEffect(() => {
  //   console.log('gtm')
  //   const tagManagerArgs = {
  //     gtmId: 'GTM-PKZGZDB',
  //   }
  //   TagManager.initialize(tagManagerArgs)
  // }, [])

  useEffect(() => {
    if (menuCategories && menuCategories.length > 0) {
      changeCategoriesMenu(menuCategories)
    }
  }, [menuCategories])
  const tryAgain = async () => {
    try {
      const response = await api.get<CustomerProps>(`/customers/me`)
      if (response.data.cookie_logout && session) {
        if (response.data.cookie_logout != session.customer.cookie_logout) {
          signOut()
        }
      }
      if (!response.data.birth_date) {
        setIsBirthdayModalOpen(true)
        if (response.data.phone_number) {
          setPhone(response.data.phone_number)
        }
      } else if (!response.data.phone_number) {
        setHasDate(true)
        setIsBirthdayModalOpen(true)
      }
    } catch (error) {
      if (session && session.customer) {
        signOut()
        router.replace(
          {
            pathname: router.pathname,
          },
          undefined,
          { shallow: true }
        )
      }
    }
  }
  // const getIpData = async () => {
  //   const res = await axios.get('https://geolocation-db.com/json/')
  //   console.log(res.data)
  //   changeIp(res.data.IPv4)
  // }
  // useEffect(() => {
  //   getIpData()
  // }, [router])
  const customersMe = async () => {
    try {
      const response = await api.get<CustomerProps>(`/customers/me`)
      if (response.data.cookie_logout && session) {
        if (response.data.cookie_logout != session.customer.cookie_logout) {
          signOut()
        }
      }
      if (!response.data.birth_date) {
        setIsBirthdayModalOpen(true)
        if (response.data.phone_number) {
          setPhone(response.data.phone_number)
        }
      } else if (!response.data.phone_number) {
        setHasDate(true)
        setIsBirthdayModalOpen(true)
      }
    } catch (error) {
      tryAgain()
      // if (session && session.customer) {
      //   signOut()
      //   router.replace(
      //     {
      //       pathname: router.pathname,
      //     },
      //     undefined,
      //     { shallow: true }
      //   )
      // }
    }
  }

  useEffect(() => {
    if (!loadingSession) {
      const handleRouteChange = (url) => {
        gtag.pageview(url, session?.customer ? session.customer.id.toString() : '')
      }
      router.events.on('routeChangeComplete', handleRouteChange)
      return () => {
        router.events.off('routeChangeComplete', handleRouteChange)
      }
    }
  }, [router.events, loadingSession, session])

  useEffect(() => {
    setOpenMenu(false)
  }, [router])

  useEffect(() => {
    if (!loadingSession && session) {
      if (session.accessToken) {
        api.defaults.headers.Authorization = `Bearer ${session.accessToken}`
        Cookies.set('customer-token', session.accessToken, { expires: 60 })
      }

      if (session.customer) {
        try {
          customersMe()
        } catch (error) {
          Sentry.captureException(error)
        }
      }
    }
  }, [loadingSession, session])

  useEffect(() => {
    if (session && router.isReady) {
      customersMe()
    }
  }, [session])

  useEffect(() => {
    if (!Cookies.get('legal-age')) {
      setCustomHaveLegalAge(false)
    }
    if (!Cookies.get('accept-cookies')) {
      setCustomerAcceptCookies(false)
    }

    const shouldLogout = Cookies.get('logout')
    if (!shouldLogout) {
      Cookies.set('logout', '5')
      if (session?.customer) {
        Logout()
      }
    }
    if (shouldLogout && shouldLogout != '5') {
      Cookies.set('logout', '5')
      if (session?.customer) {
        Logout()
      }
    }
  }, [])

  useEffect(() => {
    if (categoriesMenu && categoriesMenu.length == 0) {
      setLoadingCategories(true)
      try {
        api
          .get<CategoryPropsPrincipal>('categories?isPrincipal=true')
          .then(({ data: categories }) => {
            const newCategories = categories.categories.filter((eachCategory) => {
              const subCategories: CategoryProps[] = []
              eachCategory.childCategories.map((eachSub) => {
                const find = categories.categoriesIdsToIgnore.find((eachId) => eachId == eachSub.id)
                if (!find) {
                  subCategories.push(eachSub)
                }
              })
              eachCategory.childCategories = subCategories
              const find = categories.categoriesIdsToIgnore.find(
                (eachId) => eachId == eachCategory.id
              )
              return find ? false : true
            })
            changeCategoriesMenu(newCategories)
            setLoadingCategories(false)
          })
      } catch (error) {
        Sentry.captureException(error)
      }
    }
  }, [])

  /* useEffect(() => {
    if (customHaveLegalAge && customerHaveAcceptCookies) {
      const script = document.createElement('script')
      script.src = '//p.smrk.io/sm.js?b=ed78f415b2c2b1bd16f81d21753859b0ac00b989'
      script.id = 'smarkIo'
      document.body.appendChild(script)

      return () => {
        document.body.removeChild(script)
      }
    }
  }, [customHaveLegalAge, customerHaveAcceptCookies]) */

  const handleAcceptLegarWarning = () => {
    setCustomHaveLegalAge(true)
    Cookies.set('legal-age', 'true', { expires: 365 })
  }

  const ShowAboutUsItems = () => {
    if (window.innerWidth < 767 && !aboutUsItems) {
      setAboutUsItems(true)
    } else {
      setAboutUsItems(false)
    }
  }

  const ShowContactItems = () => {
    if (window.innerWidth < 767 && !contactItems) {
      setContactItems(true)
    } else {
      setContactItems(false)
    }
  }

  const ShowPartnerItems = () => {
    if (window.innerWidth < 767 && !partnerItems) {
      setPartnerItems(true)
    } else {
      setPartnerItems(false)
    }
  }

  const OpenMenuMobile = () => {
    setOpenMenu(!openMenu)
  }
  interface FooterLinksProps {
    title: string
    link: string
    target: string
  }
  const aboutUs: FooterLinksProps[] = [
    {
      title: 'O marketplace',
      link: '/marketplace',
      target: '',
    },
    {
      title: "FAQ'S",
      link: '/faqs',
      target: '',
    },
    {
      title: 'Blog',
      link: 'http://blog.swig.pt/',
      target: '_blank',
    },
  ]
  const contactUs: FooterLinksProps[] = [
    {
      title: 'Apoio na compra',
      link: '/ajuda',
      target: '',
    },
    {
      title: 'Devoluções',
      link: '/devolucoes',
      target: '',
    },
  ]
  const beOurPartner: FooterLinksProps[] = [
    {
      title: 'Responsabilidade social',
      link: '/responsabilidade-social',
      target: '',
    },
    {
      title: 'Retalho',
      link: '/retalhistas/tornar-se-parceiro',
      target: '',
    },
    {
      title: 'Marcas',
      link: '/marcas',
      target: '',
    },
  ]

  const handleChangeAddress = (address) => {
    //console.log(address)
    setLocalAddress(address)
  }

  const handleSelectAddress = async (address: string, placeId: string) => {
    const response = await googleMapsAutocompleteParser({ placeId: placeId })

    if (response.postal_code && response.postal_code.length > 0) {
      // if (response.postal_code && response.postal_code.length > 0) {
      // const splitedPostalCode = response.postal_code.split('-')
      // if (
      //   Number(splitedPostalCode.length == 2 ? splitedPostalCode[0] : response.postal_code) < 9000
      // ) {
      changeAddressName('')
      changeIsLoading(true)
      if (response.city && response.street) {
        setLocalAddress(`${response.street}, ${response.city}`)
      } else if (response.street) {
        setLocalAddress(response.street)
      } else if (response.city) {
        setLocalAddress(response.city)
      }
      changeClientAddressId(placeId)
      if (response.city && response.street) {
        changeClientAddress(`${response.street}, ${response.city}`)
      } else if (response.street) {
        changeClientAddress(response.street)
      } else if (response.city) {
        changeClientAddress(response.city)
      } else {
        changeClientAddress(response.formatted_address.split(',')[0])
      }
      changeClientCity(response.city)
      changeClientDistrict(response.district)
      changeClientCountry(response.country)
      changeClientPostalCode(response.postal_code)
      changeClientCoordinates({
        latitude: response.lat.toString(),
        longitude: response.lng.toString(),
      })

      window.fbq('track', 'SubmitApplication', {
        content_name: 'selected_address',
      })
      changeIsLoading(false)
      //await removeItems(items.map((item) => item.retailerProductId))
      //clearCart()
      changeDeliveryZoneError(false)
      // } else {
      //   changeDeliveryZoneError(true)
      // }
    } else {
      const responseMaps = await googleMapsAutocompleteParser({
        lat: response.lat.toString(),
        lng: response.lng.toString(),
      })
      // if (responseMaps.postal_code && responseMaps.postal_code.length > 0) {
      //   //const splitedPostalCode = responseMaps.postal_code.split('-')
      //   // if (
      //   //   Number(splitedPostalCode.length == 2 ? splitedPostalCode[0] : response.postal_code) <
      //   //   9000
      //   // ) {
      //   changeAddressName('')
      //   changeIsLoading(true)
      //   if (responseMaps.city && responseMaps.street) {
      //     setLocalAddress(`${responseMaps.street}, ${responseMaps.city}`)
      //   } else if (responseMaps.street) {
      //     setLocalAddress(responseMaps.street)
      //   } else if (responseMaps.city) {
      //     setLocalAddress(responseMaps.city)
      //   } else {
      //     setLocalAddress(response.formatted_address.split(',')[0])
      //   }
      //   changeClientAddressId(placeId)
      //   if (responseMaps.city && responseMaps.street) {
      //     changeClientAddress(`${responseMaps.street}, ${responseMaps.city}`)
      //   } else if (responseMaps.street) {
      //     changeClientAddress(responseMaps.street)
      //   } else if (responseMaps.city) {
      //     changeClientAddress(responseMaps.city)
      //   } else {
      //     changeClientAddress(response.formatted_address.split(',')[0])
      //   }
      //   changeClientCity(responseMaps.city)
      //   changeClientDistrict(responseMaps.district)
      //   changeClientCountry(responseMaps.country)
      //   changeClientPostalCode(responseMaps.postal_code)
      //   changeClientCoordinates({
      //     latitude: response.lat.toString(),
      //     longitude: response.lng.toString(),
      //   })

      //   window.fbq('track', 'SubmitApplication', {
      //     content_name: 'selected_address',
      //   })
      //   changeIsLoading(false)
      //   changeDeliveryZoneError(false)
      //   //await removeItems(items.map((item) => item.retailerProductId))
      //   //clearCart()
      //   // } else {
      //   //   changeDeliveryZoneError(true)
      //   // }
      // }
      // if (
      //   responseMaps.district != 'Madeira' &&
      //   responseMaps.district != 'Açores' &&
      //   responseMaps.district != 'Azores'
      // )
      // else {
      changeAddressName('')
      changeIsLoading(true)
      if (responseMaps.city && responseMaps.street) {
        setLocalAddress(`${responseMaps.street}, ${responseMaps.city}`)
      } else if (responseMaps.street) {
        setLocalAddress(responseMaps.street)
      } else if (responseMaps.city) {
        setLocalAddress(responseMaps.city)
      } else {
        setLocalAddress(response.formatted_address.split(',')[0])
      }
      changeClientAddressId(placeId)
      if (responseMaps.city && responseMaps.street) {
        changeClientAddress(`${responseMaps.street}, ${responseMaps.city}`)
      } else if (responseMaps.street) {
        changeClientAddress(responseMaps.street)
      } else if (responseMaps.city) {
        changeClientAddress(responseMaps.city)
      } else {
        changeClientAddress(response.formatted_address.split(',')[0])
      }
      changeClientCity(responseMaps.city)
      changeClientDistrict(responseMaps.district)
      changeClientCountry(responseMaps.country)
      changeClientPostalCode(responseMaps.postal_code)
      changeClientCoordinates({
        latitude: response.lat.toString(),
        longitude: response.lng.toString(),
      })

      window.fbq('track', 'SubmitApplication', {
        content_name: 'selected_address',
      })
      changeIsLoading(false)
      changeDeliveryZoneError(false)
      //await removeItems(items.map((item) => item.retailerProductId))
      //clearCart()
    }
    // else {
    //   changeDeliveryZoneError(true)
    // }
    // }
    // }
  }
  useEffect(() => {
    if (district && district != '') {
      if (district == 'Madeira') {
        changeClientFromIslands('madeira')
      } else if (district == 'Açores' || district == 'Azores') {
        changeClientFromIslands('açores')
      } else {
        changeClientFromIslands('')
      }
    }
  }, [district])

  const selectAddress = (Address: CustomerAddressProps) => {
    changeIsLoading(true)
    changeAddressName(Address.address_name)
    changeClientAddress(Address.address)
    changeClientAddressId(Address.address_id)
    changeClientCity(Address.city)
    changeClientDistrict(Address.district)
    changeClientCountry(Address.country)
    changeClientPostalCode(Address.postal_code)
    changeClientCoordinates({
      latitude: Address.latitude,
      longitude: Address.longitude,
    })
    //console.log('city', Address.city)
    setIsAddressDeleted(false)
    changeIsLoading(false)
  }

  useEffect(() => {
    setLocalAddress(address)
  }, [address])

  const handleAcceptCookies = () => {
    setCustomerAcceptCookies(true)
    Cookies.set('accept-cookies', 'true', { expires: 365 })
  }

  const handleChangeBirthday = async (birthDate: moment.Moment, phoneNumber) => {
    if (birthDate && phoneNumber) {
      const splitedNumber = phoneNumber.split(' ')
      const phone = Number(splitedNumber.join(''))
      if (isNaN(phone) || phoneNumber.length < 11) {
        setInvalidNumber(true)
      } else {
        setInvalidNumber(false)
        api
          .put<CustomerProps>(`customers/${session.customer.id}`, {
            birth_date: birthDate.toISOString(),
            phone_number: phoneNumber,
            newsletter: newsletterValue,
          })
          .then(() => changeUpdatedInfo(true))
        if (newsletterValue) {
          window.fbq('track', 'Subscribe', {
            content_name: 'birthday_and_phone_modal',
          })
        }
        setIsBirthdayModalOpen(false)
      }
    } else if (birthDate == null) {
      const splitedNumber = phoneNumber.split(' ')
      const phone = Number(splitedNumber.join(''))
      if (isNaN(phone) || phoneNumber.length < 11) {
        setInvalidNumber(true)
      } else {
        setInvalidNumber(false)
        api
          .put<CustomerProps>(`customers/${session.customer.id}`, {
            phone_number: phoneNumber,
          })
          .then(() => changeUpdatedInfo(true))
        setIsBirthdayModalOpen(false)
      }
    }
  }

  const getCustomerCart = async (id) => {
    try {
      const response = await api.get<MultiVendorCartProps>(`/customers/${id}/cart`)
      if (response && response.data.id) {
        changeCartId(response.data.id)
        Cookies.set('cart-id', response.data.id, { expires: 60 })
        if (response.data.items && response.data.items.length > 0) {
          //console.log('items', response.data.items)
          //const newCartProducts: MultiVendorCartItemProps[] = []
          const newCartProducts = response.data.items.map(
            (item): MultiVendorCartItemProps => {
              return {
                retailerProductId: item.retailer_product_id,
                shippingDay: item.shipping_day,
                shippingType: item.shipping_type,
                shippingTime: item.shipping_time,
                qty: item.qty,
                retailerId: item.retailer_id,
                isAfterHoursProduct: item.after_hours_product,
              }
            }
          )
          changeCartProducts(newCartProducts)
        }
      }
    } catch (error) {
      const tryAgain = async () => {
        const response = await api.get<MultiVendorCartProps>(`/customers/${id}/cart`)
        if (response && response.data.id) {
          changeCartId(response.data.id)
          Cookies.set('cart-id', response.data.id, { expires: 60 })
          if (response.data.items && response.data.items.length > 0) {
            //console.log('items', response.data.items)
            //const newCartProducts: MultiVendorCartItemProps[] = []
            const newCartProducts = response.data.items.map(
              (item): MultiVendorCartItemProps => {
                return {
                  retailerProductId: item.retailer_product_id,
                  shippingDay: item.shipping_day,
                  shippingType: item.shipping_type,
                  shippingTime: item.shipping_time,
                  qty: item.qty,
                  retailerId: item.retailer_id,
                  isAfterHoursProduct: item.after_hours_product,
                }
              }
            )
            changeCartProducts(newCartProducts)
          }
        }
      }
      tryAgain()
    }
  }

  useEffect(() => {
    if (deliveryZoneError) {
      setLocalAddress('')
    }
  }, [deliveryZoneError])

  useEffect(() => {
    if (addressError) {
      setLocalAddress('')
    }
  }, [addressError])

  useEffect(() => {
    if (session && session.customer && !loadingSession) {
      getCustomerCart(session.customer.id)
    }
  }, [session, loadingSession])

  return (
    <Layout className="layout">
      <PageSEO title={title} description={description} shareImage={shareImage} slug={slug} />
      <StyledTopHeader className="top-information-block" direction="horizontal" align="center">
        <StyledBtnTopHead type="primary" size="small" onClick={() => router.push('/marketplace')}>
          Como funciona a SW!G
        </StyledBtnTopHead>

        <Link href="https://welcome.swig.pt/">
          <a target="_blank">
            <StyledTextTopHead>
              OFERTA: Cocktail Book SWIG. Faz os teus cocktails!
            </StyledTextTopHead>
          </a>
        </Link>

        <StyledBtnTopHead
          type="primary"
          size="small"
          onClick={() => router.push('/retalhistas/tornar-se-parceiro')}
        >
          Torna-te um retalhista SW!G
        </StyledBtnTopHead>
      </StyledTopHeader>
      <StyledHeader>
        <StyledMiddleHeader className="middle-header" size={0}>
          <StyledLeftContent className="lef-content" size={42}>
            <div className={openMenu ? 'menu-mobile active' : 'menu-mobile'}>
              <Button type="link" onClick={() => OpenMenuMobile()}>
                <StyledMenuIcon />
              </Button>
              <div className={openMenu ? 'menu-mobile-items active' : 'menu-mobile-items'}>
                <Button type="link" onClick={() => OpenMenuMobile()}>
                  <StyledCloseIcon />
                </Button>
                <SearchInput />
                <StyledHeaderMenu style={{ paddingLeft: 10 }} theme="light" mode="inline">
                  {loadingCategories ? (
                    <>
                      <ContentLoader
                        speed={1}
                        height="250"
                        width="100%"
                        backgroundColor="#f3f3f3"
                        foregroundColor="#ecebeb"
                      >
                        <rect x="0" y="0" rx="0" ry="0" width="100" height="35" />
                        <rect x="0" y="60" rx="0" ry="0" width="100" height="35" />
                        <rect x="0" y="120" rx="0" ry="0" width="100" height="35" />
                        <rect x="0" y="180" rx="0" ry="0" width="100" height="35" />
                      </ContentLoader>
                    </>
                  ) : (
                    <>
                      {categoriesMenu &&
                        categoriesMenu.map((category, index) => {
                          if (category.childCategories?.length > 0) {
                            return (
                              <SubMenu key={index} title={category.name}>
                                {category.childCategories
                                  .filter((childCategory) => childCategory.is_visible == true)
                                  .slice(0, 11)
                                  .map((subcategory) => (
                                    <Menu.Item
                                      key={`${category.slug}-${index}-${subcategory.slug}`}
                                    >
                                      <Link href={`/categoria/${subcategory.slug}`}>
                                        <a
                                          aria-hidden="true"
                                          onClick={() => changeCategorySelected(subcategory)}
                                        >
                                          {subcategory.name}
                                        </a>
                                      </Link>
                                    </Menu.Item>
                                  ))}
                                <Menu.Item>
                                  <Link href={`/categoria/${category.slug}`}>
                                    <a
                                      aria-hidden="true"
                                      onClick={() => changeCategorySelected(category)}
                                      className="see-all"
                                      style={{ color: '#f16749' }}
                                    >
                                      Ver tudo
                                    </a>
                                  </Link>
                                </Menu.Item>
                              </SubMenu>
                            )
                          } else if (!category.is_sub_category && category.is_visible) {
                            if (category.is_night_category && !isVisibleAfterHours) {
                              return null
                            }

                            return (
                              <Menu.Item
                                style={{
                                  marginLeft:
                                    category.is_night_category && isVisibleAfterHours ? -10 : '',
                                }}
                                key={index}
                              >
                                <Link href={`/categoria/${category.slug}`}>
                                  {category.is_night_category && isVisibleAfterHours ? (
                                    <a>
                                      <img
                                        style={{ width: 150, height: 38 }}
                                        alt={category.name}
                                        src="/assets/images/after-hours-logo.svg"
                                      />
                                    </a>
                                  ) : (
                                    <a
                                      aria-hidden="true"
                                      onClick={() => changeCategorySelected(category)}
                                      style={{
                                        color: category.is_promotion_category ? '#f16749' : '',
                                      }}
                                    >
                                      {category.name}
                                    </a>
                                  )}
                                </Link>
                              </Menu.Item>
                            )
                          }
                        })}
                    </>
                  )}
                </StyledHeaderMenu>
                {session?.customer ? (
                  <Link href="/cliente/conta">
                    <a className="account-btn">
                      <Space>
                        <CustomerAvatar size="large" icon={<RiUserFill />} />
                        <Text> {session.customer.name} </Text>
                      </Space>
                    </a>
                  </Link>
                ) : (
                  <StyledAccountBtn type="primary" className="account-btn no-login">
                    <Link href="/cliente/entrar">
                      <a>A minha conta</a>
                    </Link>
                  </StyledAccountBtn>
                )}
              </div>
            </div>
            <Space className="logo" align="center">
              <Link href="/">
                <a aria-label="Voltar para homepage">
                  <StyledLogoImage
                    width={117}
                    height={40}
                    preview={false}
                    src="/assets/images/swig-logo.svg"
                    alt="SW!G logo"
                  ></StyledLogoImage>
                </a>
              </Link>
            </Space>
            {loadingCategories ? (
              <ContentLoader
                speed={1}
                height={35}
                width="350"
                backgroundColor="#f3f3f3"
                foregroundColor="#ecebeb"
                className="loading-categories-menu"
              >
                <rect x="0" y="0" rx="0" ry="0" width="100" height="35" />
                <rect x="125" y="0" rx="0" ry="0" width="100" height="35" />
                <rect x="250" y="0" rx="0" ry="0" width="100" height="35" />
                {/* <rect x="375" y="0" rx="0" ry="0" width="100" height="35" /> */}
              </ContentLoader>
            ) : (
              <StyledHeaderMenu theme="light" mode="horizontal">
                {categoriesMenu &&
                  categoriesMenu
                    .sort((a, b) =>
                      a.is_night_category == b.is_night_category ? 0 : a.is_night_category ? -1 : 1
                    )
                    .map((category) =>
                      renderMenuItem(category, changeCategorySelected, isVisibleAfterHours)
                    )}
              </StyledHeaderMenu>
            )}
          </StyledLeftContent>
          <StyledRightContent className="right-content" size="middle">
            <SearchInput />
            {/* <StyledDropdown overlay={menu}>
              <StyledDropdownAnchor className="ant-dropdown-link">
                PT <StyledDropdownIcon></StyledDropdownIcon>
              </StyledDropdownAnchor>
            </StyledDropdown> */}

            {session?.customer ? (
              <Dropdown overlay={optionsMenu}>
                <a className="account-btn">
                  <Space>
                    <CustomerAvatar size="large" icon={<RiUserFill />} />
                    <Text>{session.customer.name}</Text>
                  </Space>
                </a>
              </Dropdown>
            ) : (
              <StyledAccountBtn type="primary">
                <Link href="/cliente/entrar">
                  <a>A minha conta</a>
                </Link>
              </StyledAccountBtn>
            )}
            {session?.customer ? (
              <Link href="/cliente/conta">
                <a>
                  <div>
                    <RiUserFill style={{ fontSize: 25, marginTop: 5 }} />
                  </div>
                </a>
              </Link>
            ) : (
              <Link href="/cliente/entrar">
                <a>
                  <div>
                    <RiUserFill style={{ fontSize: 25, marginTop: 5 }} />
                  </div>
                </a>
              </Link>
            )}

            <Space style={{ position: 'relative' }}>
              <Link href="/carrinho">
                <a>
                  <Badge
                    count={isLoading ? 0 : totalItems()}
                    offset={[0, 20]}
                    style={{ backgroundColor: '#F16749' }}
                  >
                    <StyleShoppingFilled
                      color="#F16749"
                      style={{ fontSize: '25px', color: '#F16749' }}
                    />
                  </Badge>
                </a>
              </Link>
              {!isLoading && isMiniCartVisible && <MiniCart />}
            </Space>
          </StyledRightContent>
        </StyledMiddleHeader>
      </StyledHeader>
      <Content>{children}</Content>
      <StyledFooter>
        <StyledContainer>
          <StyledFooterSpace>
            <Col lg={6}>
              <StyledLogoSpace>
                <Link href="/">
                  <a aria-label="Voltar para homepage">
                    <img
                      width={167}
                      height={91}
                      src="/assets/images/footer-logo.svg"
                      alt="SW!G logo"
                      //quality={100}
                    ></img>
                  </a>
                </Link>
              </StyledLogoSpace>
            </Col>
            <Col lg={12}>
              <StyledRow
                justify="space-between"
                gutter={[
                  { md: 20, lg: 30, xl: 10 },
                  { xs: 8, sm: 8, md: 30, lg: 0 },
                ]}
              >
                <Col xs={24} sm={24} md={12} lg={8} xl={6}>
                  <StyledList
                    size="small"
                    header={
                      <StyledListItemHeader onClick={() => ShowAboutUsItems()}>
                        <StyledListTitle5 level={5}>Sobre nós</StyledListTitle5>
                      </StyledListItemHeader>
                    }
                    $active={aboutUsItems}
                    dataSource={aboutUs}
                    renderItem={(item: FooterLinksProps) => (
                      <StyledListItem>
                        <Link href={item.link} passHref={item.target != '' ? true : false}>
                          <StyledAnchor target={item.target}>{item.title}</StyledAnchor>
                        </Link>
                      </StyledListItem>
                    )}
                  ></StyledList>
                </Col>
                <Col xs={24} sm={24} md={12} lg={8} xl={9}>
                  <StyledList
                    size="small"
                    header={
                      <StyledListItemHeader onClick={() => ShowContactItems()}>
                        <StyledListTitle5 level={5}>Entra em contacto</StyledListTitle5>
                      </StyledListItemHeader>
                    }
                    $active={contactItems}
                    dataSource={contactUs}
                    renderItem={(item: FooterLinksProps) => (
                      <StyledListItem>
                        <Link href={item.link}>
                          <StyledAnchor>{item.title}</StyledAnchor>
                        </Link>
                      </StyledListItem>
                    )}
                  ></StyledList>
                </Col>
                <Col xs={24} sm={24} md={12} lg={8} xl={9}>
                  <StyledList
                    size="small"
                    header={
                      <StyledListItemHeader onClick={() => ShowPartnerItems()}>
                        <StyledListTitle5 level={5}>Sê nosso parceiro</StyledListTitle5>
                      </StyledListItemHeader>
                    }
                    $active={partnerItems}
                    dataSource={beOurPartner}
                    renderItem={(item: FooterLinksProps) => (
                      <StyledListItem>
                        <Link href={item.link}>
                          <StyledAnchor>{item.title}</StyledAnchor>
                        </Link>
                      </StyledListItem>
                    )}
                  ></StyledList>
                </Col>
              </StyledRow>
            </Col>
            <Col lg={6}>
              <Space direction="vertical" size={24}>
                <FolloUsWrapper direction="vertical">
                  <FolloUsWrapperTitle>Segue-nos</FolloUsWrapperTitle>
                  <Space size={16}>
                    <Link href="https://www.facebook.com/swigmarketplace">
                      <a target="_blank" rel="noreferrer">
                        Facebook
                      </a>
                    </Link>
                    <Link href="https://www.instagram.com/swig_marketplace/">
                      <a target="_blank" rel="noreferrer">
                        Instagram
                      </a>
                    </Link>
                    <Link href="https://www.linkedin.com/company/swig-marketplace/">
                      <a target="_blank" rel="noreferrer">
                        Linkedin
                      </a>
                    </Link>
                  </Space>
                </FolloUsWrapper>
                <PaymentMethodsWrapper direction="vertical">
                  <PaymentMethodsTitle>Opções de pagamento</PaymentMethodsTitle>
                  <Space size={12}>
                    <img src="/assets/images/visa.svg" alt="Visa" />
                    <img src="/assets/images/mastercard.svg" alt="MasterCard" />
                    <img src="/assets/images/mbway.svg" alt="MBWay" />
                    <img src="/assets/images/paypal.svg" alt="Paypal" />
                    <img src="/assets/images/multibanco.svg" alt="Multibanco" />
                  </Space>
                </PaymentMethodsWrapper>
              </Space>
            </Col>
          </StyledFooterSpace>
          <FooterDivider />
          <StyledFooterBottom>
            <StyledCopyright style={{ textAlign: 'left' }}>
              <StyledText>
                <StyledText>@2023 SW!G.</StyledText> Design
                <Link href="https://www.lisbonproject.com/pt/" passHref={true}>
                  <StyledTextCopyrightAnchor target="_blank" rel="noreferrer">
                    Lisbon Project
                  </StyledTextCopyrightAnchor>
                </Link>
                {' | '}
                Desenvolvimento
                <Link href="https://www.primariu.com/pt" passHref={true}>
                  <StyledTextCopyrightAnchor target="_blank" rel="noreferrer">
                    PRIMARIU
                  </StyledTextCopyrightAnchor>
                </Link>
              </StyledText>
            </StyledCopyright>
            <StyledTermsAndConditions size={30}>
              <Link href="/politica-de-privacidade">
                <a>Política de privacidade</a>
              </Link>
              <Link href="/termos-e-condicoes">
                <a>Termos e condições</a>
              </Link>
            </StyledTermsAndConditions>
            <StyledLivroReclamacoes style={{ textAlign: 'right' }}>
              <Link href="https://www.livroreclamacoes.pt/inicio">
                <a target="_blank" rel="noreferrer" aria-label="Livro de reclamações">
                  <img
                    width={163}
                    height={64}
                    src="/assets/images/livro_reclamacoes-white.png"
                    alt="Livro de reclamações"
                  ></img>
                </a>
              </Link>
            </StyledLivroReclamacoes>
            {isVisible && <WhatsappIcon linkWhatsapp={linkWhatsapp} />}
          </StyledFooterBottom>
        </StyledContainer>
        <div id="choice-footer-msg"></div>
      </StyledFooter>
      {!customHaveLegalAge && <LegalWarning acceptLegalWarning={handleAcceptLegarWarning} />}
      {/* {!customerHaveAcceptCookies && <CookiesPopup buttonCalback={handleAcceptCookies} />} */}
      <StyledModal
        title="Só mais uma coisinha..."
        visible={isBirthdayModalOpen}
        okButtonProps={{ hidden: true }}
        cancelButtonProps={{ hidden: true }}
      >
        <StyledDiv>
          {!hasDate && (
            <>
              <p className="text">
                Indica a tua data de nascimento e o número de telemóvel para poderes continuar.
              </p>
              <p className="text" style={{ color: '#393939', marginTop: 16 }}>
                <Text style={{ color: 'red' }}>*</Text> Data de nascimento
              </p>
              <DatePicker
                style={{ marginTop: '10px', marginBottom: 0 }}
                allowClear={false}
                inputReadOnly
                size="large"
                placeholder="Data de nascimento"
                format="L"
                disabledDate={(current) => current.diff(moment(), 'years') > -18}
                onChange={(date) => {
                  setDate(date)
                }}
              />
            </>
          )}

          <p className="text" style={{ color: '#393939', marginTop: 16 }}>
            <Text style={{ color: 'red' }}>*</Text> Número de telemóvel
          </p>
          <MaskedInput
            onChange={(e) => setPhone(e.target.value)}
            mask="111 111 111"
            placeholder="Número de telemóvel"
            style={{
              marginTop: '10px',
              marginBottom: 24,
              fontFamily: 'Lato',
              fontStyle: 'normal',
              fontWeight: 'normal',
              fontSize: 14,
              color: '#393939',
            }}
          />
          {invalidNumber && (
            <div
              style={{
                marginTop: -12,
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <InfoCircleOutlined
                color="#F16749"
                style={{ color: '#F16749', marginRight: 6, marginTop: 2 }}
              />
              <Text
                style={{
                  fontFamily: 'Lato',
                  fontStyle: 'normal',
                  fontWeight: 'bold',
                  fontSize: 14,
                  color: '#F16749',
                }}
              >
                Número inválido
              </Text>
            </div>
          )}

          {!hasDate ? (
            <>
              <Divider style={{ padding: 0, margin: 0 }} />
              <p
                className="text"
                style={{ fontWeight: 'bold', color: '#393939', marginTop: 20, marginBottom: 8 }}
              >
                Subscrição de newsletter
              </p>
              <Checkbox
                onChange={(value) => setNewsletterValue(value.target.checked)}
                checked={newsletterValue}
              >
                <p className="text">
                  Recebe novidades e descontos com a subscrição da nossa newsletter. Recebe já uma
                  Edição do Mobile Cocktail Book.
                </p>
              </Checkbox>
              <Button className="button" onClick={() => handleChangeBirthday(date, phone)}>
                <Text style={{ color: 'white', fontSize: 14 }}>Continuar</Text>
              </Button>
            </>
          ) : (
            <Button className="button" onClick={() => handleChangeBirthday(null, phone)}>
              <Text style={{ color: 'white', fontSize: 14 }}>Continuar</Text>
            </Button>
          )}
        </StyledDiv>
      </StyledModal>
      <Modal
        visible={addressError}
        title="Upps! Não te encontramos."
        footer={null}
        className="location-modal"
        onCancel={() => changeClientAddress('')}
      >
        <p style={{ fontFamily: 'Lato', fontSize: 16 }}>
          Estamos a ter dificuldade em validar as coordenadas desta morada. Pode ser apenas um
          problema de comunicação. Por favor, tenta novamente.
        </p>
        <ModalTopContent>
          <MapsPlacesAutocomplete
            address={localAddress}
            handleChange={handleChangeAddress}
            handleSelect={handleSelectAddress}
            isAddressDeleted={isAddressDeleted}
          />
          {localAddress && localAddress.length > 0 && (
            <Button
              type="link"
              onClick={() => {
                setLocalAddress('')
                setIsAddressDeleted(true)
              }}
            >
              Alterar
            </Button>
          )}
        </ModalTopContent>
        {session && (
          <div className="new-address">
            {addressData &&
              addressData.map((address, index) => {
                if (address.latitude && address.longitude) {
                  return (
                    <div key={index}>
                      {index > 0 && <Divider style={{ margin: '15px 0px' }} />}
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                        }}
                      >
                        <button
                          type="button"
                          className="button-new-address"
                          onClick={() => {
                            selectAddress(address)
                          }}
                        >
                          <p style={{ fontWeight: 'bold', marginBottom: 5, margin: 0, padding: 0 }}>
                            {address.address_name}
                          </p>
                          <p className="addressInfo">{address.address}</p>
                        </button>
                      </div>
                    </div>
                  )
                }
              })}
          </div>
        )}
      </Modal>
      <Modal
        visible={deliveryZoneError}
        title="Upps..."
        footer={null}
        className="location-modal"
        onCancel={() => {
          changeClientAddress('')
          changeDeliveryZoneError(false)
        }}
      >
        <p style={{ fontFamily: 'Lato', fontSize: 16, margin: 0 }}>
          Lamentamos mas a SW!G ainda não está disponivel para operar na tua morada.
        </p>
        <p style={{ fontFamily: 'Lato', fontSize: 16, marginBottom: 30 }}>
          Esperamos poder ajudar-te em breve.
        </p>
        <ModalTopContent>
          <MapsPlacesAutocomplete
            address={localAddress}
            handleChange={handleChangeAddress}
            handleSelect={handleSelectAddress}
            isAddressDeleted={isAddressDeleted}
          />
        </ModalTopContent>
        {session && addressData && (
          <div className="new-address">
            {addressData.map((address, index) => {
              if (address.latitude && address.longitude) {
                return (
                  <div key={index}>
                    {index > 0 && <Divider style={{ margin: '15px 0px' }} />}
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <button
                        type="button"
                        className="button-new-address"
                        onClick={() => {
                          selectAddress(address)
                        }}
                      >
                        <p style={{ fontWeight: 'bold', marginBottom: 5, margin: 0, padding: 0 }}>
                          {address.address_name}
                        </p>
                        <p className="addressInfo">{address.address}</p>
                      </button>
                    </div>
                  </div>
                )
              }
            })}
          </div>
        )}
      </Modal>
    </Layout>
  )
}
export default PageLayout
