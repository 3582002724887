import { Button, Space } from 'antd'
import Text from 'antd/lib/typography/Text'
import Title from 'antd/lib/typography/Title'
import Image from 'next/image'
import Link from 'next/link'
import {
  StyledLegalWarning,
  StyledLegalWarningInnerWrapper,
  StyledLegalWarningWrapper,
  StyledLogoImage,
} from './styles'

interface LegalWarningProps {
  acceptLegalWarning: () => void
}

const LegalWarning = ({ acceptLegalWarning }: LegalWarningProps) => {
  return (
    <StyledLegalWarning>
      <StyledLegalWarningWrapper>
        <StyledLegalWarningInnerWrapper>
          <StyledLogoImage
            width={257}
            height={140}
            preview={false}
            src="/assets/images/swig-full-logo.svg"
          ></StyledLogoImage>
          <Title level={4} className="legar-warning-title">
            Confirmação de idade (+18)
          </Title>
          <Text className="legar-warning-description">
            O marketplace da SW!G destina-se a pessoas com idade legal para o consumo de bebidas
            alcoólicas.
          </Text>
          <Text className="ask-age-title"> Tens mais de 18 anos? </Text>
          <Space className="ask-age-buttons">
            <Button type="primary" onClick={() => acceptLegalWarning()}>
              Sim
            </Button>
            <Button type="link"> Não </Button>
          </Space>
          <Space className="legar-warning-links">
            <Link href="/politica-de-privacidade">
              <a> Política de privacidade </a>
            </Link>
            <Link href="/termos-e-condicoes">
              <a> Termos e condições </a>
            </Link>
          </Space>
        </StyledLegalWarningInnerWrapper>
      </StyledLegalWarningWrapper>
    </StyledLegalWarning>
  )
}
export { LegalWarning }
