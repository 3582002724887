import styled from 'styled-components'
import { Button, Input, Space } from 'antd'
import Title from 'antd/lib/typography/Title'
import Image from 'next/image'

export const StyledSpaceBanner = styled(Space)`
  //background-image: url('/assets/images/home-banner.png');
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 640px;
  justify-content: center;
  position: relative;

  > .ant-space-item {
    z-index: 9;
  }

  @media (max-width: 767px) {
    height: 560px;
  }

  & > div:first-child {
    @media (max-width: 767px) {
      padding: 0 24px;
    }
  }

  & > .ant-space-item:nth-child(1) {
    @media (max-width: 767px) {
      display: block;
      overflow: hidden;
      inset: 0px;
      box-sizing: border-box;
      margin: 0px;
      margin-right: 0px !important;
      padding: 0 !important;
    }
  }

  & > .ant-space-item:nth-child(3) {
    @media (max-width: 767px) {
      padding: 0 24px;
    }
  }

  & > .ant-space-item .content .ant-space-item:nth-child(3) {
    @media (max-width: 767px) {
      margin-bottom: 0 !important;
    }
  }
`

export const StyledTitleH1 = styled(Title)`
  font-weight: 800 !important;
  line-height: 63px !important;
  margin-bottom: 0 !important;
  max-width: 760px;
  text-align: center;
  @media (max-width: 767px) {
    font-size: 32px !important;
    line-height: 40.32px !important;
    text-align: center;
    width: 100%;
    margin: auto;
    white-space: unset;
  }

  @media (max-width: 400px) {
    width: 100%;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    font-size: 40px !important;
    line-height: 52px !important;
  }
`

export const StyledTitleH5 = styled(Title)`
  line-height: 26px !important;
  margin-bottom: 20px !important;
  text-align: center;
  font-family: 'Lato';
  @media (max-width: 767px) {
    font-size: 16px !important;
    line-height: 24px !important;
    margin-bottom: 10px !important;
  }
`
export const StyledSearchContainer = styled(Space)`
  width: 100%;
  justify-content: center;

  & > div:first-child {
    @media (max-width: 767px) {
      flex: 0 100%;
    }
  }
`

export const StyledBtn = styled(Button)`
  height: 48px !important;
  padding-left: 15px;
  padding-right: 15px;
`

export const StyledInput = styled(Input)`
  width: 517px;
  color: #f16749;
  font-size: 16px;
  font-family: Lato;
  font-weight: 400;
  line-height: 24px;
  @media (max-width: 767px) {
    width: 100%;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    width: 400px;
  }

  & input {
    font-size: 16px;
  }
`

export const StyledBtnLocation = styled(Button)`
  width: 100%;
  display: none;
  margin-top: -20px;
  padding: 0 22px;
  @media (max-width: 767px) {
    display: block;
  }

  @media (max-width: 350px) {
    display: flex;
    white-space: unset;
    text-overflow: ellipsis;
    padding: 10px 20px;
    height: auto;
  }
`

export const StyledSpaceBannerBackgroundImage = styled(Image)`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
`
export const SponsoredTag = styled.div`
  background-color: rgb(247, 247, 247);
  color: #24282f;
  border-radius: 2px;
  padding: 2px 4px;
  position: absolute;
  right: 20px;
  bottom: 10px;
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 9px;
  line-height: 14px;
  z-index: 9;
  /* identical to box height, or 156% */

  /* Neutral / White */
`
