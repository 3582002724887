import styled from 'styled-components'
import { Space } from 'antd'

export const StyledContainer = styled(Space)`
  max-width: 1400px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  @media (max-width: 767px) {
    padding: 0 24px;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    padding: 0 30px;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    padding: 0 30px;
  }

  @media (min-width: 1200px) and (max-width: 1450px) {
    padding: 0 30px;
  }
`
