// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
export const GA_TRACKING_ID = process.env.NEXT_PUBLIC_GA_ID

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const pageview = (url: string, userId: string) => {
  if (userId == '') {
    window.gtag('config', GA_TRACKING_ID, {
      page_path: url,
    })
  } else {
    window.gtag('config', GA_TRACKING_ID, {
      page_path: url,
      user_id: userId,
    })
  }
}

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const event = ({ action, category, label, value }) => {
  window.gtag('event', action, {
    event_category: category,
    event_label: label,
    value: value,
  })
}
