/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { ConfigurableProductProps } from '@/Props/ConfigurableProductProps'
import api from '@/services/api'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useState, useRef, useEffect } from 'react'
import { StyledDropDow, StyledSearch, StyledSearchInput, StyledSearchInputWrapper } from './styles'
import * as Sentry from '@sentry/nextjs'
import { useClient } from '@/contexts/ClientContext'
import { AiOutlineArrowRight } from 'react-icons/ai'
import { useCategory } from '@/contexts/CategoryContext'
import { CategoryProducts, ServerSideProps } from '@/components/CategoryTypesense'
import Cookies from 'js-cookie'
import { browserName, browserVersion, osName, osVersion } from 'react-device-detect'
import { useSession } from 'next-auth/client'

const SearchInput = () => {
  const { showSpinnerSearchInput, changeShowSpinnerSearchInput } = useClient()
  const router = useRouter()
  const node = useRef() as React.MutableRefObject<HTMLInputElement>
  const [isInputActive, setIsInputActive] = useState(true)
  const [inputValue, setInputValue] = useState('')
  const [listWithSearchResults, setListWithSearchResults] = useState<ServerSideProps['products']>(
    []
  )
  const [isResultsVisible, setIsResultsVisible] = useState(false)
  const [hasResults, setHasResults] = useState(true)
  const {
    changeIsSearchPage,
    changeSearchTerms,
    changeSearchWord,
    changeFilteredProducts,
    changeClearAttributesOptions,
    changeSearchWordPage,
  } = useCategory()
  const [session] = useSession()

  const handleSearchInputChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    changeIsSearchPage(false)
    const searchTerm = e.target.value
    // if (searchTerm.length == 0) {
    //   setListWithSearchResults([])
    //   setHasResults(false)
    // }
    changeSearchWord(searchTerm)
    setInputValue(searchTerm)
    const newSearchTerm = searchTerm.toLocaleLowerCase().split(' ')
    let newSearchTermLength = newSearchTerm.length
    newSearchTerm.map((each, index) => {
      if (each.length < 1) {
        newSearchTerm.splice(index, 1)
        newSearchTermLength = newSearchTerm.length
      }
    })

    // changeShowSpinnerSearchInput(true)
    // setIsResultsVisible(true)
    // api
    //   .get<ServerSideProps['products']>(
    //     `search-products?typesense=true&q=${searchTerm}
    //     `
    //   )
    //   .then(async ({ data: searchProductsResult }) => {
    //     console.log('search', searchProductsResult)
    //     setListWithSearchResults(searchProductsResult)
    //     changeShowSpinnerSearchInput(false)
    //   })

    if (newSearchTerm.length > 0 && newSearchTerm[newSearchTermLength - 1].length > 3) {
      changeShowSpinnerSearchInput(true)
      setIsResultsVisible(true)
      api
        .get<ServerSideProps['products']>(
          `search-products?typesense=true&q=${newSearchTerm[newSearchTermLength - 1]}
        `
        )
        .then(async ({ data: searchProductsResult }) => {
          const mostApproximated: ServerSideProps['products'] = []
          const evenMostApproximated: ServerSideProps['products'] = []
          const lessApproximated: ServerSideProps['products'] = []
          if (searchProductsResult) {
            newSearchTerm.map((eachTerm, index) => {
              const eachTermSplited = eachTerm.split('')
              searchProductsResult.map((eachResult) => {
                if (
                  eachResult.name == eachTerm ||
                  // eachResult.name.toLocaleLowerCase().includes(`${eachTerm}`) ||
                  eachResult.name
                    .toLocaleLowerCase()
                    .includes(`${newSearchTerm[index - 1]} ${eachTerm}`) ||
                  eachResult.name
                    .toLocaleLowerCase()
                    .includes(`${eachTerm} ${newSearchTerm[index + 1]}`)
                ) {
                  if (
                    eachResult.name
                      .toLocaleLowerCase()
                      .includes(
                        `${newSearchTerm[index - 2]} ${newSearchTerm[index - 1]} ${eachTerm}`
                      ) ||
                    eachResult.name
                      .toLocaleLowerCase()
                      .includes(
                        `${newSearchTerm[index - 3]} ${newSearchTerm[index - 2]} ${
                          newSearchTerm[index - 1]
                        } ${eachTerm}`
                      ) ||
                    eachResult.name
                      .toLocaleLowerCase()
                      .includes(
                        `${eachTermSplited[0]}${eachTermSplited[1]}${eachTermSplited[2]}${eachTermSplited[3]}${eachTermSplited[4]}`
                      )
                  ) {
                    evenMostApproximated.push(eachResult)
                    //searchProductsResult.splice(index, 1)
                  } else {
                    mostApproximated.push(eachResult)
                    //searchProductsResult.splice(index, 1)
                  }
                } else if (
                  eachResult.name.toLocaleLowerCase().includes(`${eachTerm}`) ||
                  eachResult.name
                    .toLocaleLowerCase()
                    .includes(
                      `${eachTermSplited[0]}${eachTermSplited[1]}${eachTermSplited[2]}${eachTermSplited[3]}${eachTermSplited[4]}`
                    )
                ) {
                  if (
                    eachResult.name
                      .toLocaleLowerCase()
                      .includes(
                        `${eachTermSplited[0]}${eachTermSplited[1]}${eachTermSplited[2]}${eachTermSplited[3]}${eachTermSplited[4]}${eachTermSplited[5]}`
                      )
                  ) {
                    mostApproximated.push(eachResult)
                    //searchProductsResult.splice(index, 1)
                  } else {
                    lessApproximated.push(eachResult)
                    //searchProductsResult.splice(index, 1)
                  }
                }
              })
            })
            lessApproximated.map((each) => {
              const find = searchProductsResult.findIndex((product) => product.name == each.name)
              if (find != -1) {
                searchProductsResult.splice(find, 1)
              }
              searchProductsResult.unshift(each)
              // lessApproximated.splice(index, 1)
            })
            mostApproximated.map((each) => {
              const findedIndex = lessApproximated.findIndex((product) => product.name == each.name)
              const find = searchProductsResult.findIndex((product) => product.name == each.name)
              if (findedIndex != -1) {
                lessApproximated.splice(findedIndex, 1)
              }
              if (find != -1) {
                searchProductsResult.splice(find, 1)
              }
              searchProductsResult.unshift(each)
              // mostApproximated.splice(index, 1)
            })
            evenMostApproximated.map((each) => {
              const find = searchProductsResult.findIndex((product) => product.name == each.name)
              const findedIndex = lessApproximated.findIndex((product) => product.name == each.name)
              const findedOther = mostApproximated.findIndex((product) => product.name == each.name)
              if (findedIndex != -1) {
                lessApproximated.splice(findedIndex, 1)
              }
              if (findedOther != -1) {
                mostApproximated.splice(findedOther, 1)
              }
              if (find != -1) {
                searchProductsResult.splice(find, 1)
              }
              searchProductsResult.unshift(each)
              //evenMostApproximated.splice(index, 1)
            })
            // searchProductsResult.map((eachProduct, index) => {
            //   const findIndex = searchProductsResult.findIndex(
            //     (product) => product.name == eachProduct.name
            //   )
            //   if (findIndex != -1 && findIndex != index) {
            //     searchProductsResult.splice(index, 1)
            //   }
            // })
            setListWithSearchResults(searchProductsResult)
          }

          changeShowSpinnerSearchInput(false)
        })
      // try {
      //   const { data: searchProductsResult } = await api.get<ConfigurableProductProps[]>(
      //     `/search-products`,
      //     {
      //       params: {
      //         q: newSearchTerm[newSearchTermLength - 1],
      //       },
      //     }
      //   )
      // } catch (error) {
      //   Sentry.captureException(error)
      //   changeShowSpinnerSearchInput(false)
      // }
    }

    // show spinner
  }

  useEffect(() => {
    // setIsResultsVisible(showSpinnerSearchInput)
  }, [showSpinnerSearchInput])

  useEffect(() => {
    if (listWithSearchResults.length == 0 && isResultsVisible) {
      setHasResults(false)
    } else {
      setHasResults(true)
    }
  }, [listWithSearchResults])

  /*const handleSearchIconClick = () => {
    setIsInputActive(!isInputActive)
  }*/

  const handleClickOutside = (e) => {
    if (node != undefined && node.current != undefined) {
      if (node.current?.contains(e.target)) {
        // inside click
        return
      }
    }
    // outside click
    setInputValue('')
    setIsInputActive(true)
    setIsResultsVisible(false)
    setListWithSearchResults([])
  }

  useEffect(() => {
    if (isInputActive) {
      document.addEventListener('mousedown', handleClickOutside)
    } else {
      document.removeEventListener('mousedown', handleClickOutside)
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [isInputActive])

  const handleOnPressEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
    changeIsSearchPage(true)
    setIsResultsVisible(false)
    changeFilteredProducts([])
    changeClearAttributesOptions(true)
    const searchTerm = e.currentTarget.value
    if (searchTerm.length > 0) {
      changeSearchWordPage(searchTerm)
      changeShowSpinnerSearchInput(true)
      let email = Cookies.get('clientEmail')
      let name = Cookies.get('clientName')
      if (!email && session && session.customer && session.customer.email) {
        email = session.customer.email
        name = session.customer.name
      }
      if (email || (session && session.customer && session.customer.email)) {
        api
          .post(`search-for-term`, {
            email: email,
            name: name ? name : '',
            browser: `${browserName} v${browserVersion}`,
            os: `${osName} v${osVersion}`,
            searchTerm: searchTerm,
          })
          .then(() => {
            router.push(`/pesquisa?p=${searchTerm}`)
          })
          .catch(() => {
            router.push(`/pesquisa?p=${searchTerm}`)
          })
      } else {
        router.push(`/pesquisa?p=${searchTerm}`)
      }
    }
  }

  return (
    <StyledSearchInputWrapper ref={node} $isInputActive={isInputActive}>
      <StyledSearchInput
        onChange={handleSearchInputChange}
        value={inputValue}
        allowClear
        loading={showSpinnerSearchInput ? true : false}
        $isInputActive={isInputActive}
        onPressEnter={handleOnPressEnter}
        placeholder="Pesquisar cerveja, vinho..."
      ></StyledSearchInput>
      <StyledDropDow direction="vertical" size={0}>
        {isResultsVisible && listWithSearchResults.length == 0 && <p>Sem Resultados ...</p>}
        {isResultsVisible &&
          listWithSearchResults.slice(0, 5).map((listProduct, index) => {
            return (
              <Link key={index} href={`/produto/${listProduct.slug}`}>
                <a
                  onClick={() => {
                    window.fbq('track', 'Search', {
                      search_string: inputValue,
                    })
                  }}
                >
                  {' '}
                  {listProduct.name}{' '}
                </a>
              </Link>
            )
          })}
        {isResultsVisible && listWithSearchResults.length > 5 && (
          <Link href={`/pesquisa?p=${inputValue}`}>
            <a
              className="see-all"
              onClick={async () => {
                changeShowSpinnerSearchInput(true)
                changeSearchWordPage(inputValue)
                setIsResultsVisible(false)
                let email = Cookies.get('clientEmail')
                let name = Cookies.get('clientName')
                if (!email && session && session.customer && session.customer.email) {
                  email = session.customer.email
                  name = session.customer.name
                }
                if (email || (session && session.customer && session.customer.email)) {
                  await api.post(`search-for-term`, {
                    email: email,
                    name: name ? name : '',
                    browser: `${browserName} v${browserVersion}`,
                    os: `${osName} v${osVersion}`,
                    searchTerm: inputValue,
                  })
                }

                window.fbq('track', 'Search', {
                  search_string: inputValue,
                })
              }}
            >
              Ver todos os resultados <AiOutlineArrowRight></AiOutlineArrowRight>
            </a>
          </Link>
        )}
      </StyledDropDow>
    </StyledSearchInputWrapper>
  )
}

export { SearchInput }
