import { useClient } from '@/contexts/ClientContext'
import { useMultiVendorCart } from '@/contexts/MultiVendorCartContext'
import { CartProductInfoProps } from '@/Props/CartProductInfoProps'
import { ConfigurableProductProps } from '@/Props/ConfigurableProductProps'
import api from '@/services/api'
import formatPriceWithCurrency from '@/utils/formatPriceWithCurrency'
import { VerifyIfProductInPromotion } from '@/utils/VerifyIfProductInPromotion'
import { Button, Divider, Image, InputNumber, Space } from 'antd'
import Text from 'antd/lib/typography/Text'
import Link from 'next/link'
import { useCallback, useEffect, useState } from 'react'
import {
  EmptyMiniCartSubTitle,
  EmptyMiniCartTitle,
  MiniCartItem,
  MiniCartItems,
  MiniCartItemsWrapper,
  MiniCartTitle,
  MiniCartWrapper,
  TotalPriceWrapper,
} from './styles'

interface CartItemProps {
  configurableId: number
  retailerProductId: number
  simpleProductId: number
  qty: number
  stock: number
}

interface MiniCartWithItemsProps {
  items: CartProductInfoProps[]
}

const EmptyMiniCart = () => {
  return (
    <Space size={12} direction="vertical">
      <EmptyMiniCartTitle>O teu carrinho parece estar vazio...</EmptyMiniCartTitle>
      <EmptyMiniCartSubTitle>Vamos mudar isso!</EmptyMiniCartSubTitle>
      <Button type="primary" block>
        Começar a comprar
      </Button>
    </Space>
  )
}

const MiniCartWithItems = ({ items }: MiniCartWithItemsProps) => {
  const {
    updateItemQty,
    getSubTotalPrice,
    //calculateShippingPrices,
  } = useMultiVendorCart()
  /*  const { latitude, longitude, address } = useClient()
  const [shippingPrice, setShippingPrice] = useState(0)
  
  useEffect(() => {
    if (items) {
      calculateShippingPrices(items).then((shipping) => {
        setShippingPrice(shipping.totalShippingPrice)
      })
    }
  }, [items]) */

  return (
    <MiniCartItemsWrapper>
      <MiniCartItems direction="vertical">
        {items.map((item, key) => (
          <MiniCartItem key={key}>
            <Image width={47} preview={false} src={item.image} />
            <Space direction="vertical">
              <Text className="product-name">{item.name}</Text>
              {/*<Text className="product-options">{item.options}</Text>*/}
            </Space>
            <InputNumber
              defaultValue={item.qty}
              min={1}
              step={1}
              onChange={(qty: number) => {
                updateItemQty({
                  ...item,
                  qty,
                })
              }}
            />
            <Text className="product-price" style={{ marginLeft: 5 }}>
              {formatPriceWithCurrency(
                VerifyIfProductInPromotion({
                  specialPrice: item.special_price,
                  specialPriceBeginDate: item.special_price_begin_date,
                  specialPriceEndDate: item.special_price_end_date,
                })
                  ? item.special_price * item.qty
                  : item.price * item.qty
              )}
            </Text>
          </MiniCartItem>
        ))}
      </MiniCartItems>
      <TotalPriceWrapper>
        <Text className="total-price-label">Total</Text>
        <Text className="total-price-value">
          {formatPriceWithCurrency(getSubTotalPrice(items))}
        </Text>
      </TotalPriceWrapper>
      {/* 
      <TotalPriceWrapper>
        <Text className="total-price-label" style={{ fontWeight: 'normal', fontSize: 15 }}>
          Subtotal
        </Text>
        <Text className="total-price-value" style={{ fontWeight: 'normal', fontSize: 15 }}>
          {formatPriceWithCurrency(getSubTotalPrice(items))}
        </Text>
      </TotalPriceWrapper>
      <TotalPriceWrapper style={{ marginTop: 3 }}>
        <Text className="total-price-label" style={{ fontWeight: 'normal', fontSize: 15 }}>
          Entrega
        </Text>
        <Text className="total-price-value" style={{ fontWeight: 'normal', fontSize: 15 }}>
          {formatPriceWithCurrency(shippingPrice)}
        </Text>
      </TotalPriceWrapper>
      <Divider style={{ margin: 0, marginTop: 5, marginBottom: -5, padding: 0 }} />
      <TotalPriceWrapper style={{ marginBottom: 15 }}>
        <Text className="total-price-label" style={{ fontSize: 15 }}>
          Total
        </Text>
        <Text className="total-price-value" style={{ fontSize: 15 }}>
          {formatPriceWithCurrency(getSubTotalPrice(items) + shippingPrice)}
        </Text>
      </TotalPriceWrapper>
      */}
      <Button type="primary" block>
        <Link href="/carrinho">
          <a> Finalizar compra</a>
        </Link>
      </Button>
    </MiniCartItemsWrapper>
  )
}

const MiniCart = () => {
  const { cartProducts, getCartProductsInformation } = useMultiVendorCart()
  const [isLoading, setIsLoading] = useState(false)
  const [newCartProducts, setNewCartProducts] = useState<CartProductInfoProps[]>([])
  useEffect(() => {
    const getCart = async () => {
      setIsLoading(true)
      const products = await getCartProductsInformation()
      //console.log('products', products)
      setNewCartProducts(products)
      setIsLoading(false)
    }
    getCart()
  }, [cartProducts])

  if (isLoading) {
    return <Text> A carregar ... </Text>
  }

  return (
    <MiniCartWrapper direction="vertical" className="mini-cart">
      <MiniCartTitle>Carrinho</MiniCartTitle>
      {newCartProducts.length > 0 ? (
        <MiniCartWithItems items={newCartProducts} />
      ) : (
        <EmptyMiniCart />
      )}
    </MiniCartWrapper>
  )
}

export { MiniCart }
