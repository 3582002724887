import Head from 'next/head'

interface PageSEOProps {
  title?: string
  description?: string
  shareImage?: string
  slug: string
}

const PUBLIC_URL = process.env.NEXT_PUBLIC_WEBSITE_URL
const developMode = process.env.NEXT_PUBLIC_NODE_ENV

const PageSEO = ({ title, description, shareImage, slug }: PageSEOProps) => {
  const shareUrl = PUBLIC_URL + slug

  const robots = developMode == 'development' ? 'noindex, nofollow' : 'index, follow'

  return (
    <Head>
      {/* Primary Meta Tags */}
      <title>{title}</title>
      <meta name="title" content={title} />
      <meta name="description" content={description} />

      {/* Open Graph / Facebook */}
      <meta property="og:type" content="website" />
      <meta property="og:url" content={shareUrl} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={shareImage} />

      {/*  Twitter  */}
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content={shareUrl} />
      <meta property="twitter:title" content={title} />
      <meta property="twitter:description" content={description} />
      <meta property="twitter:image" content={shareImage} />

      {/* ROBOTS */}
      <meta name="robots" content={robots} />
      <meta name="googlebot" content={robots} />
      <meta name="googlebot-news" content={robots} />
    </Head>
  )
}

export { PageSEO }
