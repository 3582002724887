import { WhatsappIconWrapper } from './style'

const WhatsappIcon = ({ linkWhatsapp }) => {
  return (
    <WhatsappIconWrapper>
      <a href={linkWhatsapp} target="_blank" rel="noreferrer" className="whatsapp-icon">
        <span className="whatsapp-image" />
      </a>
    </WhatsappIconWrapper>
  )
}
export default WhatsappIcon
