import styled from 'styled-components'
import { Button, Space, Typography } from 'antd'
import { ArrowRightOutlined } from '@ant-design/icons'
import Text from 'antd/lib/typography/Text'

export const StyledSpace = styled(Space)`
  width: 100%;
  padding: 9px 0;
  border-bottom: 1px solid rgba(67, 4, 59, 0.2);
  justify-content: center;

  @media (max-width: 480px) {
    & {
      padding: 9px 27px;
      width: 100%;
      overflow: hidden;
    }

    & > .ant-space-item:nth-child(3) {
      width: 100%;
      text-overflow: ellipsis;
      overflow: hidden;
      height: 24px;
    }

    & > .ant-space-item:nth-child(3) .ant-btn {
      width: 100%;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: unset;
      text-align: left;
    }
  }
`

export const StyledTextDelivery = styled(Text)`
  font-family: Lato;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #43043b;
`

export const StyledText = styled(Button)`
  font-family: Lato;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #43043b;
  height: 24px;
  padding: 0;
`
export const StyledArrow = styled(ArrowRightOutlined)`
  color: #43043b;
  padding-left: 11px;
  padding-right: 11px;
`

export const AddressInfoTopBarWithoutAddress = styled.div`
  background-image: url('/assets/images/home-banner.png');
  background-size: cover;
  background-position: center;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 48px 0;
  display: flex;
  flex-direction: column;
  .moon-after-hours {
    max-width: 235px;
    max-height: 238px;
    width: 100%;
    height: 100%;
    margin-bottom: -48px;
    margin-right: 20px;
  }
  .after-hours-banner-wrapper {
    max-width: 1400px;
    width: 100%;
    margin: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .after-hours-banner-text-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  @media (min-width: 1200px) and (max-width: 1450px) {
    .after-hours-banner-wrapper {
      padding: 0 30px;
    }
  }
  @media (max-width: 1199px) {
    .after-hours-banner-wrapper {
      padding-left: 24px;
      padding-right: 24px;
    }
  }
  .after-hours-title {
    font-family: 'Sora';
    font-style: normal;
    font-weight: 800;
    font-size: 50px;
    line-height: 63px;
    color: #ffffff;
    padding: 0;
    margin: 0;
  }
  .after-hours-subtitle {
    font-family: 'Sora';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;
    color: #ffffff;
    margin: 12px 0 20px !important;
  }

  @media (max-width: 767px) {
    padding: 48px 24px;
    text-align: center;
    .after-hours-banner-wrapper {
      flex-direction: column;
    }
    .moon-after-hours {
      max-width: 145px;
      max-height: 147px;
      align-items: center;
      margin: 3px auto -48px;
    }
    .after-hours-banner-wrapper {
      padding-left: 0px;
      padding-right: 0px;
    }
  }
`

export const AddressInfoCategoryTitle = styled(Typography.Title)`
  @media (max-width: 767px) {
    &.ant-typography {
      font-family: Sora;
      font-style: normal;
      font-weight: 800;
      font-size: 32px;
      line-height: 40px;
      text-align: center;
      margin-bottom: 0;
    }
  }
`

export const AddressInfoCategorySmallDescription = styled(Typography.Title)`
  @media (max-width: 767px) {
    &.ant-typography {
      font-family: Lato;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 24px;
      margin-top: 24px;
      margin-bottom: 42px;
    }
  }
`

export const StyledBtn = styled(Button)`
  height: 48px !important;
  padding-left: 15px;
  padding-right: 15px;
`
export const ModalBottomContent = styled.div`
  padding-top: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  & .ant-alert {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    background: #fdf6e2;
    border-color: #f2c94c;
    width: 100%;
    align-items: flex-start;

    & .ant-alert-content .ant-alert-message {
      color: #393939;
    }
  }

  & .ant-btn {
    font-size: 14px;
  }

  .ant-alert-warning .ant-alert-icon {
    margin-top: 5px;
  }
`

export const ModalTopContent = styled.div`
  display: flex;
  position: relative;

  & > div {
    flex: 1;
    width: auto;
  }

  & > div .location-search-input {
    width: 100%;
  }

  & > .ant-btn {
    position: absolute;
    right: 1px;
    top: 2px;
    height: 45px;
    padding: 0;
    z-index: 9;
    padding-right: 16px;
    background: #ffff;
    font-size: 14px;
  }

  & .ant-input-affix-wrapper > input.ant-input {
    @media (max-width: 767px) {
      max-width: 70%;
      text-overflow: ellipsis;
    }
  }
`

export const AutoCompleteWrapper = styled.div`
  display: flex;
  @media (max-width: 767px) {
    width: 100%;

    > div {
      flex: 1;
    }
  }
`
